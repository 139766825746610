<template>
  <div class="operators">
    <div class="operator-character">
<!--      <a-tooltip placement="bottom" title="Control + Enter">-->
          <a-dropdown-button type="primary" :disabled="running" :loading="running" @click="onRun('run')">
            <cc-iconfont v-if="!running" name="run" :color="running?'#999':'#ffffff'" :size="12"></cc-iconfont>
            <a-icon v-if="running" type="loading" />
            执行
            <a-menu slot="overlay">
              <a-menu-item :key="run.key" v-for="run in Object.values(runTypes)" @click="onRun(run.key)">{{run.label}}</a-menu-item>
            </a-menu>
            <a-icon slot="icon" type="down" />
          </a-dropdown-button>
<!--      </a-tooltip>-->
      <a-button @click="handleStop" :disabled="!running" style="margin-left: 10px" :loading="stopping">
        <cc-iconfont v-if="!stopping" name="stop" :color="running?'#555555':'#999'" :size="12"></cc-iconfont>
        中断执行
      </a-button>
      <a-button @click="onRun('asyncList')" style="margin-left: 10px">
        异步任务
      </a-button>
      <a-popover placement="right" v-if="tab.dataSourceType === 'Redis'" style="margin-left: 10px;vertical-align: middle">
        <template slot="content">
          <a-table :pagination="false" :columns="tipColumns" :data-source="tipData" size="small">
          </a-table>
        </template>
        <template slot="title">
          <span style="font-size: 16px">Redis常用指令</span>
        </template>
        <cc-iconfont :size="18" name="help" color="#bbb"/>
      </a-popover>
      <div :class="`operator-character ${running?'active':'not-active'}`">
      </div>
      <div class="float-right">
        <div class="transaction" v-if="userInfo.global && userInfo.global.consoleTransaction && supportTransaction(tab.dataSourceType)">
          <div class="auto-commit-select" style="display: inline-block">
            <a-tooltip placement="bottom">
              <div slot="title">
                <p>Manual：开启事务，需手动提交事务</p>
                <p>Auto：关闭事务，执行的SQL自动提交</p>
                <p>使用默认的事务隔离级别</p>
              </div>
              <span class="pointer">事务</span>
            </a-tooltip>
            <a-select v-model="autoCommit" style="width: 100px;margin:0 8px" @change="handleChangeTx">
              <a-select-option value="Auto">自动提交</a-select-option>
              <a-select-option value="Manual">手动提交</a-select-option>
            </a-select>
          </div>
          <a-button v-if="autoCommit==='Manual'" @click="handleCommit" style="margin-right: 8px" :disabled="!tab.readyToCommit">
            <cc-iconfont name="commit" :color="running?'#555555':'#999'" :size="12"></cc-iconfont>
            提交
          </a-button>
          <a-button v-if="autoCommit==='Manual'" @click="handleRollback" style="margin-right: 10px" :disabled="!tab.readyToCommit">
            <cc-iconfont name="back" :color="running?'#555555':'#999'" :size="12"></cc-iconfont>
            回滚
          </a-button>
        </div>
<!--        <a-tooltip placement="bottom">-->
<!--          <template slot="title">-->
<!--            <span>清除编辑器内容</span>-->
<!--          </template>-->
<!--          <a-button @click="handleClean" class="clean-icon">-->
<!--            <cc-iconfont name="del" color="#555555" :size="14"></cc-iconfont>-->
<!--          </a-button>-->
<!--        </a-tooltip>-->
      </div>
<!--      <div class="float-right">-->
<!--        <cc-filter-select selectStyle="width: 140px" vModal="tab.database" :defaultValue="tab.database" :disabled="tab.readyToCommit"-->
<!--                          :handleChange="handleTabDb" placeholder="请选择库" :dataList="tab.dbList"></cc-filter-select>-->
<!--        <cc-filter-select v-if="hasSchema(tab.dataSourceType)" selectStyle="width: 140px;margin-left: 10px"-->
<!--                          vModal="tab.schema" :defaultValue="tab.schema" :handleChange="handleTabSchema" :disabled="tab.readyToCommit"-->
<!--                          placeholder="请选择schema" :dataList="tab.schemaList"></cc-filter-select>-->
<!--&lt;!&ndash;        <a-select v-model="tab.database" style="width: 140px" @change="handleTabDb" placeholder="请选择库"&ndash;&gt;-->
<!--&lt;!&ndash;                  :filter-option="filterOption" show-search>&ndash;&gt;-->
<!--&lt;!&ndash;          <a-select-option v-for="db in tab.dbList" :value="db.name" :key="db.name">{{ db.name }}</a-select-option>&ndash;&gt;-->
<!--&lt;!&ndash;        </a-select>&ndash;&gt;-->
<!--&lt;!&ndash;        <a-select v-if="hasSchema(tab.dataSourceType)" v-model="tab.schema" style="width: 120px;margin-left: 10px"&ndash;&gt;-->
<!--&lt;!&ndash;                  @change="handleTabSchema" :filter-option="filterOption" show-search&ndash;&gt;-->
<!--&lt;!&ndash;                  placeholder="请选择schema">&ndash;&gt;-->
<!--&lt;!&ndash;          <a-select-option v-for="schema in tab.schemaList" :value="schema.name" :key="schema.name">{{&ndash;&gt;-->
<!--&lt;!&ndash;              schema.name&ndash;&gt;-->
<!--&lt;!&ndash;            }}&ndash;&gt;-->
<!--&lt;!&ndash;          </a-select-option>&ndash;&gt;-->
<!--&lt;!&ndash;        </a-select>&ndash;&gt;-->
<!--      </div>-->
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { hasSchema, supportTransaction } from '../../../utils/index';

export default {
  name: 'Operators',
  props: {
    onRun: Function,
    tab: Object,
    handleClean: Function,
    handleCommit: Function,
    handleRollback: Function,
    running: Boolean,
    stopping: Boolean,
    handleStop: Function,
    handleTabDb: Function,
    allDsList: Array,
    handleTabSchema: Function,
    setReadyToCommit: Function
  },
  data() {
    return {
      runTypes: {
        runAsync: { key: 'runAsync', label: '异步执行' }
        // ticket: { key: 'ticket', label: '提交工单' }
      },
      autoCommit: 'Auto',
      hasSchema,
      supportTransaction,
      tipColumns: [
        {
          title: '指令',
          key: 'instruction',
          dataIndex: 'instruction'
        },
        {
          title: '作用',
          key: 'function',
          dataIndex: 'function'
        }
      ],
      tipData: [
        {
          instruction: 'GET key',
          function: '获取键值'
        },
        {
          instruction: 'RENAME key newkey',
          function: '更改键名称'
        },
        {
          instruction: 'SET key value',
          function: '存放键值'
        },
        {
          instruction: 'KEYS pattern',
          function: '获取符合该模式的键'
        },
        {
          instruction: ' EXISTS key [key ...]',
          function: '查询键是否存在'
        },
        {
          instruction: 'DEL key [key ...]',
          function: '删除键'
        },
        {
          instruction: 'TTL key',
          function: '查询key的生命周期（秒）'
        },
        {
          instruction: ' EXPIRE key seconds [NX|XX|GT|LT]',
          function: '设置过期时间'
        }
      ]
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo
    })
  },
  mounted() {
    if (this.tab.autoCommit === false) {
      this.autoCommit = 'Manual';
    }
    if (supportTransaction(this.tab.dataSourceType)) {
      this.handleChangeTx(this.autoCommit);
    }
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    async setAutoCommit(tx) {
      const data = {
        sessionId: this.tab.sessionId,
        autoCommit: tx === 'Auto'
      };
      const res = await this.$services.setAutocommit({ data });
      if (res.success) {
        this.tab.autoCommit = tx;
        if (tx === 'Manual') {
          this.tab.autoCommit = false;
        } else {
          this.tab.autoCommit = true;
        }
      }
    },
    handleChangeTx(value) {
      if (value === 'Manual') {
        this.tab.autoCommit = false;
        if (this.tab.sessionId) {
          this.setTx(false);
        }
      } else {
        this.tab.autoCommit = true;
        if (this.tab.sessionId) {
          this.setTx(true);
        }
      }
      // this.setReadyToCommit(this.tab);
    },
    async setTx(data) {
      const res = await this.$services.setTx({
        data: {
          sessionId: this.tab.sessionId,
          autoCommit: data
        }
      });
      // eslint-disable-next-line no-empty
      if (res.success) {

      }
    },
    handleInstanceChange() {

    }
  }
};
</script>
