<template>
  <div class="edit-column">
    <div class="struct-table-content">
      <vxe-table
        ref="columnTable"
        :data="editStructData"
        :height="viewHeight - advancedHeight - 65"
        border
        highlight-current-column
        highlight-current-row
        highlight-hover-column
        highlight-hover-row
        keep-source
        show-overflow
        show-status
        size="mini"
        @cell-click="handleCellClick"
        @edit-closed="handleEditBlur"
        @edit-actived="handleEditActive"
      >
        <vxe-table-column :edit-render="{}" field="name" title="名称">
          <template #edit="{row, rowIndex}">
            <vxe-input v-model="row.name" @blur="updateColumn(row, rowIndex)"/>
          </template>
        </vxe-table-column>
        <vxe-table-column :edit-render="{}" field="dbType" title="类型">
          <template #edit="{row}">
            <vxe-pulldown ref="typeDown">
              <template #default>
                <vxe-input v-model="row.dbType" :placeholder="row.dbType" @focus="focusEvent"
                           @keyup="keyupEvent"></vxe-input>
              </template>
              <template #dropdown>
                <div v-if="SEARCH_ALL_DATA_TYPE.length" class="type-down">
                  <div v-for="item in SEARCH_ALL_DATA_TYPE" :key="item" class="list-item"
                       @click="selectEvent(row, item)">
                    <i class="fa fa-user-o"></i>
                    <span style="width: 100px;">{{ item }}</span>
                  </div>
                </div>
              </template>
            </vxe-pulldown>
          </template>

        </vxe-table-column>
        <vxe-table-column :edit-render="{}" title="长度">
          <template #default="{row}">
            <div v-if="ALL_DATA_TYPE_MAP[row.dbType] === 'datetime'">{{ row.datetimePrecision }}</div>
            <div v-else-if="ALL_DATA_TYPE_MAP[row.dbType] === 'text'">{{ row.length }}</div>
            <div v-else-if="ALL_DATA_TYPE_MAP[row.dbType] === 'decimal' || ALL_DATA_TYPE_MAP[row.dbType] === 'number'">{{ row.numericPrecision }}</div>
          </template>
          <template #edit="{row, rowIndex}">
            <vxe-input v-if="ALL_DATA_TYPE_MAP[row.dbType] === 'datetime'" v-model="row.datetimePrecision" type="number"
                       @blur="updateColumn(row, rowIndex)"/>
            <vxe-input v-else-if="ALL_DATA_TYPE_MAP[row.dbType] === 'text'" v-model="row.length"
                       type="number"
                       @blur="updateColumn(row, rowIndex)"/>
            <vxe-input v-else-if="ALL_DATA_TYPE_MAP[row.dbType] === 'decimal' || ALL_DATA_TYPE_MAP[row.dbType] === 'number'" v-model.number="row.numericPrecision" type="number"/>
          </template>
        </vxe-table-column>
        <vxe-table-column title="NULL">
          <template #default="{row}">
            <vxe-checkbox v-model="row.nullable" disabled close-label="否"
                          open-label="是" @change="handleBlur('nullable', row, $event)"/>
          </template>
        </vxe-table-column>
        <vxe-table-column title="主键">
          <template #default="{row}">
            <vxe-checkbox :value="primaryKey && primaryKey.columnList && primaryKey.columnList.includes(row.name)" disabled/>
          </template>
        </vxe-table-column>
        <vxe-table-column :edit-render="{name: 'input', attrs: {type: 'text'}}" field="comment" title="备注"/>
      </vxe-table>
    </div>
    <a-modal v-model="showAddEnum" border title="添加包含的值列表">
      <div v-if="showAddEnum">
        <vxe-table ref="enumTable"
                   :data="selectedColumn.enumArr"
                   :edit-config="{trigger: 'click', mode: 'cell', showStatus: true}"
                   height="300"
                   highlight-current-row
                   keep-source
                   show-overflow
                   size="small"
                   @current-change="handleEnumClick"
        >
          <vxe-table-column type="seq"/>
          <vxe-table-column :edit-render="{name: 'input'}" field="enum" title="添加包含的值列表"/>
        </vxe-table>
        <a-button @click="addEnum">添加</a-button>
        <a-button @click="removeEnum">删除</a-button>
        <a-button @click="saveEnum">保存</a-button>
        <a-button @click="showAddEnum=false">取消</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import Vue from 'vue';
import { DB_TYPE } from '@/consts';
import { Modal } from 'ant-design-vue';

const EMPTY_COLUMN = {
  attributeMap: {
    bytes_max_length: 'null',
    characters_max_length: 'null',
    default_character_set_name: null,
    default_collation_name: null
  },
  columnType: '',
  datetimePrecision: null,
  dbType: '',
  name: '',
  nullable: false,
  numericPrecision: null,
  numericScale: null
};
export default {
  name: 'EditColumn',
  props: {
    initEditorData: Object,
    history: Array,
    deleteHistory: Function,
    primaryKey: Object,
    structData: Array,
    viewHeight: Number,
    pushHistory: Function,
    schemaEditorApply: Function,
    tab: Object,
    isMySQL: Boolean
  },
  computed: {
    // editStructData() {
    //   return cloneDeep(this.structData);
    // }
  },
  data() {
    return {
      ALL_DATA_TYPE: [],
      ALL_DATA_TYPE_MAP: {},
      column_name: {
        name: '',
        newName: ''
      },
      validRules: {
        dbType: [
          { required: true, message: '数据类型不能为空' }
        ]
      },
      editStructData: [],
      structDataObj: {},
      showAddEnum: false,
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      selectedColumn: {},
      editedColumn: {},
      selectedEnum: {},
      advancedHeight: 0,
      ENUM_TYPE: [],
      SET_TYPE: [],
      addColumns: [],
      deleteColumns: []
    };
  },
  methods: {
    handleBlur(attr, row = this.selectedColumn) {
      const columns = {};
      Object.keys(DB_TYPE)
        .forEach((c) => {
          columns[DB_TYPE[c]] = row[c];
        });
      columns.TYPE = row.dbType;
      const type = 'COLUMN_CHANGE';
      let args = null;
      switch (attr) {
        case 'nullable':
          if (row.nullable === this.editedColumn.nullable) {
            return;
          }
          break;
        case 'defaultValue':
          if (row.defaultValue === this.editedColumn.defaultValue) {
            return;
          }
          break;
        default:
          break;
      }

      switch (attr) {
        case 'nullable':
        case 'defaultValue':
          args = {
            COLUMNS: [{
              NAME: this.editedColumn.name,
              ...columns
            }]
          };
          break;
        default:
          break;
      }

      if (args) {
        const action = {
          type,
          args
        };
        this.pushHistory(action);
      }
    },
    handleEditActive(event) {
      const { row } = event;
      this.editedColumn = cloneDeep(row);
    },
    async handleEditBlur(event) {
      if (this.editedColumn.new) {
        return;
      }
      const {
        row,
        column
      } = event;

      // const $table = this.$refs.columnTable;
      // const errMap = await $table.validate(row).catch((err) => err);
      //
      // if (errMap || row.new) {
      //   return;
      // }
      switch (column.title) {
        case '名称':
          if (row.name === this.editedColumn.name) {
            return;
          }
          break;
        case '类型':
          if (row.dbType === this.editedColumn.dbType) {
            return;
          }
          break;
        case '长度':
          if (row.length === this.editedColumn.length && row.numericPrecision === this.editedColumn.numericPrecision && row.datetimePrecision === this.editedColumn.datetimePrecision) {
            return;
          }
          break;
        case '备注':
          if (row.comment === this.editedColumn.comment) {
            return;
          }
          break;
        case 'NULL':
          if (row.nullable === this.editedColumn.nullable) {
            return;
          }
          break;
        default:
          break;
      }
      let type = '';
      let args = null;
      const columns = {};
      Object.keys(DB_TYPE)
        .forEach((c) => {
          columns[DB_TYPE[c]] = row[c];
        });
      columns.TYPE = row.dbType;
      columns.DEFAULT = row.defaultValue;
      switch (column.title) {
        case '名称':
          type = 'COLUMN_RENAME';
          args = {
            NAME: this.editedColumn.name,
            NEW_NAME: row.name
          };
          this.column_name = {
            name: this.editedColumn.name,
            newName: row.name
          };
          if (!this.editedColumn.new) {
            this.schemaEditorApply([{
              type,
              args
            }]);
          }

          return;
        case '类型':
        case '长度':
        case '备注':
        case 'NULL':
          type = 'COLUMN_CHANGE';
          args = {
            COLUMNS: [{
              NAME: this.editedColumn.name,
              ...columns
            }]
          };
          break;
        default:
          break;
      }
      if (type && args) {
        const action = {
          type,
          args
        };
        this.pushHistory(action);
      }
    },
    updateColumn() {
      // row.edited = true;
      // if (type === 'primaryKey' && row.primaryKey) {
      //   row.nullable = false;
      // }
      // Vue.set(this.editStructData, index, row);
    },
    handleEnumClick({ row }) {
      this.selectedEnum = row;
    },
    saveEnum() {
      const data = this.$refs.enumTable.getTableData();
      this.ENUM_TYPE = [...data.tableData.map((item) => item.enum)];
      this.showAddEnum = false;
    },
    async removeEnum() {
      await this.$refs.enumTable.remove(this.selectedEnum);
    },
    async addEnum() {
      const record = { enum: '' };
      const { row: newRow } = await this.$refs.enumTable.insertAt(record, -1);
      await this.$refs.enumTable.setActiveCell(newRow, 'enum');
    },
    handleShowAddEnum() {
      const arr = [];
      this.selectedColumn.enum.forEach((item) => {
        arr.push({ enum: item });
      });
      Vue.set(this.selectedColumn, 'enumArr', arr);
      this.showAddEnum = true;
    },
    calcAdvancedHeight() {
      let advancedHeight = 0;
      if (this.$refs.advanced) {
        advancedHeight = this.$refs.advanced.getBoundingClientRect().height;
      }
      this.advancedHeight = advancedHeight;
    },
    handleCellClick(e) {
      this.selectedColumn = cloneDeep(e.row);
      this.$nextTick(() => {
        this.calcAdvancedHeight();
      });
    },
    async addColumn() {
      const { row: newRow } = await this.$refs.columnTable.insertAt(EMPTY_COLUMN, -1);
      newRow.new = true;
      await this.$refs.columnTable.setActiveRow(newRow);
      await this.$refs.columnTable.setCurrentRow(newRow);
      await this.$refs.columnTable.setActiveCell(newRow, 'name');
      this.selectedColumn = cloneDeep(newRow);
      this.editedColumn = cloneDeep(newRow);
      const { _XID } = newRow;
      this.addColumns.push(_XID);
      this.editStructData.push(newRow);
      this.$nextTick(() => {
        this.calcAdvancedHeight();
      });
    },
    async removeColumn() {
      await this.$refs.columnTable.removeCurrentRow();
      const { _XID, name } = this.selectedColumn;
      const index = this.addColumns.indexOf(_XID);
      const historyIndex = this.history.findIndex((h) => h.type === 'COLUMN_ADD' && h.args.COLUMNS[0].NAME === name);

      if (index > -1 || historyIndex > -1) {
        if (index > -1) {
          this.addColumns.splice(index, 1);
        }

        if (historyIndex > -1) {
          this.deleteHistory(historyIndex);
        }
      } else {
        this.deleteColumns.push(_XID);
        const actions = [];
        const deletes = [];
        this.editStructData.forEach((column) => {
          if (_XID === column._XID) {
            deletes.push({ NAME: column.name });
          }
        });

        if (deletes.length) {
          const deleteAction = {
            type: 'COLUMN_DROP',
            args: {
              COLUMNS: deletes
            }
          };
          actions.push(deleteAction);
        }
        this.schemaEditorApply(actions, false, true);
      }

      const editIndex = this.editStructData.findIndex((c) => c._XID === _XID);
      this.editStructData.splice(editIndex, 1);
      this.selectedColumn = {};

      this.$nextTick(() => {
        this.calcAdvancedHeight();
      });
    },
    focusEvent() {
      this.$refs.typeDown.showPanel();
    },
    switchTab(history = false) {
      const insertRecords = [];
      let hasError = false;
      let errorData = {};

      this.editStructData.forEach((column) => {
        if (column.new) {
          insertRecords.push(column);
        }
      });
      const adds = [];
      const preStructNames = this.initEditorData.editorData.columnList.map((index) => index.name);
      insertRecords.forEach((record) => {
        if (!record.name && !hasError) {
          hasError = true;
          errorData = {
            data: record,
            key: 'name',
            msg: '请填写名称'
          };
        }

        if (!record.dbType && !hasError) {
          hasError = true;
          errorData = {
            data: record,
            key: 'dbType',
            msg: '请填写类型'
          };
        }

        if (record.name && !hasError && preStructNames.includes(record.name)) {
          hasError = true;
          errorData = {
            data: record,
            key: 'name',
            msg: `${record.name}列名称重复`
          };
        }

        const columns = {};
        Object.keys(DB_TYPE)
          .forEach((c) => {
            columns[DB_TYPE[c]] = record[c];
          });
        columns.TYPE = record.dbType;

        if (!hasError) {
          adds.push({
            type: 'COLUMN_ADD',
            args: {
              COLUMNS: [columns]
            }
          });
        }
      });

      if (adds.length && !hasError) {
        if (history) {
          adds.forEach((add) => {
            this.pushHistory(add);
          });
        } else {
          this.schemaEditorApply(adds, false, true);
        }
      }

      if (hasError) {
        Modal.error({
          title: errorData.msg,
          okText: '确定',
          onOk: async () => {
            await this.$refs.columnTable.setActiveRow(errorData.data);
            await this.$refs.columnTable.setCurrentRow(errorData.data);
            await this.$refs.columnTable.setActiveCell(errorData.data, errorData.key);
          }
        });
      }

      return !hasError;
    },
    keyupEvent(e) {
      this.$refs.typeDown.showPanel();
      const { value } = e;
      this.SEARCH_ALL_DATA_TYPE = value ? this.ALL_DATA_TYPE.filter((item) => item.indexOf(value) > -1) : this.ALL_DATA_TYPE;
    },
    selectEvent(row, type) {
      this.editStructData = this.editStructData.map((item) => {
        if (item.name === row.name) {
          item.dbType = type;
        }
        return item;
      });
      this.$refs.typeDown.hidePanel()
        .then(() => {
          this.SEARCH_ALL_DATA_TYPE = this.ALL_DATA_TYPE;
        });

      this.$forceUpdate();
    },
    async getTypes() {
      const res = await this.$services.schemaEditorTypes({ data: { targetDsIdentity: this.tab.dataSourceType } });
      if (res.success) {
        this.ALL_DATA_TYPE = Object.keys(res.data);
        this.SEARCH_ALL_DATA_TYPE = [...Object.keys(res.data)];
        this.ALL_DATA_TYPE_MAP = res.data;
      }
    }
  },
  watch: {
    structData: {
      handler(newValue) {
        const structDataObj = {};
        newValue.forEach((column) => {
          if (column.name === this.column_name.newName) {
            this.selectedColumn = column;
            this.editedColumn = column;
          }
          structDataObj[column.name] = column;
        });
        this.structDataObj = structDataObj;
        this.editStructData = cloneDeep(newValue);
        this.column_name = {};
      },
      immediate: true
    }
  },
  created() {
    this.getTypes();
    const structDataObj = {};
    this.structData.forEach((column) => {
      structDataObj[column.name] = column;
    });
    this.structDataObj = structDataObj;
    this.editStructData = cloneDeep(this.structData);
    this.isMySQL = this.tab.dataSourceType === 'MySQL';
    // this.INT = ['BIGINT', 'TINYINT', 'SMALLINT', 'MEDIUMINT', 'INT'];
    // this.BOOL = ['BOOL', 'BOOLEAN'];
    // this.BIT = ['BIT'];
    // this.FLOAT = ['DECIMAL', 'FLOAT', 'DOUBLE'];
    // this.TIME = ['DATE', 'DATETIME', 'TIMESTAMP', 'TIME', 'YEAR'];
    // this.CHAR = ['CHAR', 'VARCHAR'];
    // this.BINARY = ['BINARY', 'VARBINARY', 'TINYBLOB', 'BLOB', 'MEDIUMBLOB', 'LONGBLOB'];
    // this.TEXT = ['TINYTEXT', 'MEDIUMTEXT', 'LONGTEXT', 'TEXT'];
    // this.ENUM = ['ENUM', 'SET'];
    // this.JSON = ['JSON'];
    // this.ENUM_TYPE = ['enum1', 'enum2', 'enum3', 'enum4', 'enum5', 'enum6', 'enum7', 'enum8', 'enum9'];
    // this.SET_TYPE = ['set1', 'set2', 'set3', 'set4', 'set5', 'set6', 'set7', 'set8', 'set9'];
    //
    // this.ADVANCED_TYPE = [...this.INT, ...this.FLOAT, ...this.TIME, ...this.CHAR, ...this.TEXT, ...this.ENUM];
    // const ALL_DATA_TYPE = [...this.INT, ...this.BOOL, ...this.BIT, ...this.FLOAT, ...this.TIME, ...this.CHAR, ...this.BINARY, ...this.TEXT, ...this.ENUM, ...this.JSON];
    // this.ALL_DATA_TYPE = [...ALL_DATA_TYPE];
    // this.SEARCH_ALL_DATA_TYPE = [...ALL_DATA_TYPE];
  }
};
</script>

<style lang="less" scoped>
.edit-column {
  .struct-table-content {
  }

  .advanced {
  }

  .op {
    padding: 0 5px;
    width: 100%;
    height: 40px;
    border-top: 1px solid #eee;
    display: flex;
    align-items: center;
  }
}

.type-down {
  max-height: 200px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #dcdfe6;
  overflow: scroll;

  .list-item:hover {
    background-color: #f5f7fa;
    cursor: pointer;
  }
}

.ant-form .ant-form-item {
  margin-bottom: 0 !important;
}
</style>
