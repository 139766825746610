<template>
  <a-modal :visible="visible" @cancel="handleCloseModal" title="Insert SQL" :width="1240" :mask-closable="false">
    <div class="content">
      <div v-for="(sql, index) in sqls" :key="index">
        {{ sql }}
      </div>
    </div>
    <div class="footer">
      <a-button type="primary" ghost @click="copy">复制</a-button>
      <a-button @click="handleCloseModal">关闭</a-button>
    </div>
  </a-modal>
</template>

<script>
import copyMixin from '../../../../mixins/copyMixin';

export default {
  name: 'InsertSqlModal',
  mixins: [copyMixin],
  props: {
    visible: Boolean,
    sqls: Array,
    handleCloseModal: Function
  },
  methods: {
    copy() {
      this.copyText(this.sqls.join('\n'));
      // this.$Message.success('复制成功！');
    }
  }
};
</script>

<style scoped lang="less">
.content {
  border: 1px solid rgba(218, 218, 218, 1);
  padding: 10px;
  max-height: 540px;
  overflow: scroll;
}
</style>
