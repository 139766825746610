<template>
  <div class="data-source-body">
    <NoDataTip v-if="dataSourceList.length<1"></NoDataTip>
    <CCTree v-if="dataSourceList.length>=1" :data="dataSourceList" :load-data="getDataSourceList"
            @on-contextmenu="handleContextMenu" @on-db-select-change="handleDbClickMenu" @on-select-change="handleClickMenu">
      <template slot="contextMenu">
        <div>
          <div v-if="selectedData&&selectedData.type === 'db'">
            <DropdownItem>
              <div @click="handleNewQuery(selectedData)">新建查询窗口</div>
            </DropdownItem>
            <DropdownItem>
              <div @click="handleCopyInfo(selectedData, 'title')">复制库名</div>
            </DropdownItem>
            <DropdownItem>
              <div @click="handleGoTicket">提交工单</div>
            </DropdownItem>
          </div>
          <div v-if="selectedData&&selectedData.type === 'instance'">
            <DropdownItem>
              <div @click="handleNewQuery(selectedData)">新建查询窗口</div>
            </DropdownItem>
            <DropdownItem>
              <div @click="handleCopyInfo(selectedData, 'defaultHost')">复制连接串</div>
            </DropdownItem>
          </div>
          <div v-if="selectedData&&selectedData.type === 'schema'">
            <DropdownItem>
              <div @click="handleNewQuery(selectedData)">新建查询窗口</div>
            </DropdownItem>
            <DropdownItem>
              <div @click="handleCopyInfo(selectedData, 'title')">复制schema名称</div>
            </DropdownItem>
          </div>
        </div>
      </template>
    </CCTree>
  </div>
</template>
<script>
import CCTree from '@views/sql/components/Tree/Index.vue';
import NoDataTip from '@views/sql/components/NoDataTip.vue';
import { hasSchema } from '@utils';

export default {
  name: 'Menu',
  props: {
    dataSourceList: Array,
    getDataSourceList: Function,
    handleNewQuery: Function,
    handleShowStruct: Function,
    handleShowData: Function,
    getTableList: Function
  },
  components: {
    CCTree,
    NoDataTip
  },
  data() {
    return {
      selectedData: {},
      hasSchema
    };
  },
  mounted() {
  },
  methods: {
    handleGoTicket() {
      if (this.selectedData.type === 'db') {
        localStorage.setItem('instanceToTicket', this.selectedData.instanceId);
        localStorage.setItem('databaseToTicket', this.selectedData.title);
        localStorage.setItem('dsTypeToTicket', this.selectedData.dataSourceType);
      } else if (this.selectedData.type === 'table') {
        localStorage.setItem('instanceToTicket', this.selectedData.instanceId);
        localStorage.setItem('databaseToTicket', this.selectedData.db);
        localStorage.setItem('schemaToTicket', this.selectedData.schema);
      }
      this.$router.push({
        path: '/ticket_create'
      });
    },
    handleContextMenu(data) {
      this.selectedData = data;
    },
    handleDbClickMenu(data1, data2) {
      this.selectedData = data2;
      this.handleNewQuery(data2);
    },
    handleClickMenu(data1, data2) {
      this.selectedData = data2;
      if (this.selectedData.type === 'addDb') {
        this.getTableList(this.selectedData);
      } else {
        data2.selected = true;
      }
    },
    handleCopyInfo(data, type) {
      const input = document.createElement('textarea');

      input.value = data[type];
      document.body.appendChild(input);
      input.select();
      document.execCommand('Copy');
      document.body.removeChild(input);

      this.$Message.success('复制成功！');
    }
  }
};
</script>
