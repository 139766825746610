var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"edit-index"},[_c('vxe-grid',{ref:"index_table",attrs:{"columns":_vm.columns,"data":_vm.editIndexData,"size":"mini","height":_vm.viewHeight - 65,"border":"","highlight-current-column":"","highlight-current-row":"","highlight-hover-column":"","highlight-hover-row":"","show-overflow":""},on:{"cell-click":_vm.handleCellClick,"edit-closed":_vm.handleEditBlur,"edit-actived":_vm.handleEditActive},scopedSlots:_vm._u([{key:"name_edit",fn:function(ref){
var row = ref.row;
var rowIndex = ref.rowIndex;
return [_c('vxe-input',{on:{"blur":function($event){return _vm.updateRow(row, rowIndex, ['name'])}},model:{value:(row.name),callback:function ($$v) {_vm.$set(row, "name", $$v)},expression:"row.name"}})]}},{key:"column_edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-select',{attrs:{"multiple":"","transfer":""},model:{value:(row.columnList),callback:function ($$v) {_vm.$set(row, "columnList", $$v)},expression:"row.columnList"}},_vm._l((_vm.columnNameList),function(column){return _c('vxe-option',{key:column,attrs:{"value":column,"label":column}})}),1)]}},{key:"type_edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-select',{attrs:{"size":"mini","transfer":""},on:{"change":_vm.handleChangeIndex},model:{value:(row.type),callback:function ($$v) {_vm.$set(row, "type", $$v)},expression:"row.type"}},_vm._l((_vm.typeArr),function(t){return _c('vxe-option',{key:t,attrs:{"value":t,"label":t}})}),1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }