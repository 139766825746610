<template>
  <div class="sql-viewer">
    <Operators :onRun="onRun" :tab="tab" :handleClean="handleClean" :setReadyToCommit="setReadyToCommit"
               :handleCommit="handleCommit" :handleTabSchema="handleTabSchema"
               :handleRollback="handleRollback" :running="running" :handleStop="handleStop"
               :handleTabDb="handleTabDb" :allDsList="allDsList" :stopping="stopping"
    />
    <MyEditor :updateMyMirror="updateMyMirror" :tab="tab" ref="theCodemirror" type="query"
              :storeQueryTabs="storeQueryTabs" :id="`codemirror${tab.key}`"
              :onRun="onRun"></MyEditor>
    <div :id="`dragBar${tab.key}`" class="dragBar"></div>
    <div class="sql-error" v-if="errorMessage&&showError">
      <a-icon type="close" class="sql-error-close-btn" @click="handleCloseError"/>
      {{ errorMessage }}
    </div>
  </div>
</template>
<script>
import Operators from '@views/sql/components/Operators.vue';
import MyEditor from '@views/sql/components/MyEditor.vue';
import { Modal } from 'ant-design-vue';
import { hasSchema } from '../../../utils/index';
import { BIZ_TYPE } from '../../../consts';
import sqlMixin from '../../../mixins/sqlMixin';

export default {
  name: 'SqlViewer',
  components: {
    Operators,
    MyEditor
  },
  props: {
    getEditor: Function,
    tab: Object,
    storeQueryTabs: Function,
    resize: Function,
    allDsList: Array,
    listTableForTab: Function,
    createSession: Function,
    updateResultSpining: Function
  },
  mixins: [sqlMixin],
  mounted() {
    // if (this.tab.key === 'tab') {
    document.onmousedown = this.mouseDonwHandler;
    // }
  },
  data() {
    return {
      canRun: true,
      running: false,
      stopping: false,
      editorOptions: {
        mode: 'text/javascript'
      },
      myCodeMirror: '',
      newCode: '',
      showSql: '',
      resultArray: [],
      selectionMoveCursorActivity: false,
      dragBarPayload: {},
      code: '',
      columnNameList: [],
      tables: [],
      errorMessage: '',
      ws: null,
      showError: false
    };
  },
  methods: {
    updateMyMirror(editor) {
      this.myCodeMirror = editor;
    },
    documentMouseMoveHandler(event) {
      const HEADER_HEIGHT = 122;
      const diff = event.clientY
        - this.dragBarPayload.codemirror.offsetHeight
        - HEADER_HEIGHT;

      this.resize(diff, this.tab.key);
    },
    mouseDonwHandler(event) {
      if (event.target.classList.contains('dragBar')) {
        const {
          clientY,
          target
        } = event;

        this.dragBarPayload.clientY = clientY;
        this.dragBarPayload.codemirror = target.previousElementSibling.firstElementChild;
        document.onmousemove = this.documentMouseMoveHandler;
        document.onmouseup = () => {
          document.onmousemove = null;
        };
      }
    },
    async analysisSplit(selectedSql) {
      const data = {
        dataSourceType: this.tab.dataSourceType,
        queryString: selectedSql
      };
      const res = await this.$services.analysisSplit({ data });
      if (res.success) {
        this.tab.resultList = [];
        if (res.data.itemList) {
          for (let i = 0; i < res.data.itemList.length; i++) {
            const result = this.executeQuery(res.data.itemList[i].queryString);
            if (!result) {
              break;
            }
          }
        }
      } else {
        this.running = false;
        this.tab.running = false;
        this.updateResultSpining(false);
        this.errorMessage = res.msg;
        this.showError = true;
      }
    },
    async executeQuery(selectedSql) {
      const data = {
        sessionId: this.tab.sessionId,
        queryString: selectedSql,
        queryTimeout: 10
      };
      const res = await this.$services.executeQuery({ data });
      this.running = false;
      this.tab.running = false;
      this.updateResultSpining(false);
      if (res.success) {
        res.data.resultSet.map((item) => {
          this.tab.resultList.push(item);
          if (!item.success) {
            this.errorMessage = item.message;
            this.showError = true;
          }
          this.tab.executeInfo.push({
            database: this.tab.database,
            queryBody: selectedSql,
            message: item.message,
            success: item.success,
            time: item.time
          });
          return null;
        });
        return true;
      }
      this.errorMessage = res.msg;
      this.showError = true;
      return false;
    },
    handleRun(selectedSql) {
      this.running = true;
      this.tab.running = true;
      this.updateResultSpining(true);
      this.errorMessage = '';
      this.showError = false;
      const data = {
        type: 'WS_EXEC',
        object: {
          sessionId: this.tab.sessionId,
          queryString: selectedSql,
          dataSourceId: this.tab.instanceId,
          currentDb: this.tab.schema ? this.tab.database : null,
          currentSchema: this.tab.schema || this.tab.database,
          rdbTxStatue: !this.tab.autoCommit
        }
      };
      this.tab.resultList = [];
      this.createWebSocket();
      if (!this.tab.autoCommit) {
        this.tab.readyToCommit = true;
        this.storeQueryTabs();
      }
      this.ws.onclose = () => {
        console.log(`llws连接关闭!${new Date().toLocaleString()}`);
      };
      this.ws.onerror = () => {
        console.log('llws连接错误!');
        this.running = false;
        this.tab.running = false;
        this.updateResultSpining(false);
      };
      this.ws.onopen = () => {
        console.log(`llws连接成功!${new Date().toLocaleString()}`, data);
        this.ws.send(JSON.stringify(data));
      };
      this.ws.onmessage = (event) => { // 如果获取到消息，心跳检测重置
        const queryData = JSON.parse(event.data);
        if (queryData.type === 'WS_RESULT' || queryData.type === 'WS_FAILED') {
          this.tab.resultList.push(queryData.object);
          if (!this.tab.executeInfo) {
            this.tab.executeInfo = [];
          }
          this.tab.executeInfo.push({
            database: this.tab.database,
            queryBody: queryData.object.queryBody,
            message: queryData.object.message,
            success: queryData.object.success,
            time: queryData.time
          });
        } else if (queryData.type === 'WS_ERROR') {
          if (queryData.object.code === 507) {
            const next = () => {
              this.onRun();
            };
            this.createSession(this.tab, next);
          } else {
            this.errorMessage = `【error】${queryData.object.object}`;
          }
          // this.tab.resultList.push(queryData.object);
          this.showError = true;
        } else if (queryData.type === 'WS_RULES') {
          this.errorMessage = `【安全规则】${queryData.object.ruleNames.join('；')}`;
          this.showError = true;
        } else if (queryData.type === 'WS_DONE') {
          this.ws.close();
          this.running = false;
          this.tab.running = false;
          this.updateResultSpining(false);
        }
      };
    },
    async handleRunAsync(selectedSql) {
      const data = {
        dataSourceId: this.tab.instanceId,
        exportSqls: selectedSql,
        bizType: BIZ_TYPE.QUERY_CONSOLE,
        dataSourceType: this.tab.dataSourceType,
        currentDb: this.tab.schema ? this.tab.database : null,
        currentSchema: this.tab.schema || this.tab.database,
        exportBizId: this.tab.sessionId
      };
      const res = await this.$services.submitExportJob({ data });
      if (res.success) {
        this.$message.success('异步任务提交成功');
      }
    },
    handleRunTicket(selectedSql) {
      this.goTicketPage(selectedSql);
    },
    async onRun(type = 'run') {
      console.log(type);
      let selectedSql = '';
      if (type !== 'asyncList') {
        this.tab.queryText = this.myCodeMirror.getValue();
        this.storeQueryTabs();
        const selectionValue = this.myCodeMirror.getSelection();
        if (selectionValue) {
          selectedSql = this.myCodeMirror.getSelection();
        } else {
          const selection = document.getElementsByClassName('selected-span');
          if (selection) {
            Array.prototype.forEach.call(selection, (element) => {
              selectedSql += element.innerText;
            });
          }
        }
        if (selectedSql.length > 131072) {
          this.errorMessage = '【error】一次性执行的SQL的大小超过限制，请批量执行';
          this.showError = true;
          return;
        }
        if (!selectedSql) {
          Modal.warning({
            title: '执行异常提示',
            content: '您当前没有选中任何SQL语句，请选中后再执行。',
            okText: '知道了'
          });
          return;
        }
      }

      switch (type) {
        case 'run':
          this.handleRun(selectedSql);
          break;
        case 'runAsync':
          await this.handleRunAsync(selectedSql);
          this.onRun('asyncList');
          break;
        case 'ticket':
          this.handleRunTicket(selectedSql);
          break;
        case 'asyncList':
          if (!this.tab.asyncListTab) {
            this.tab.asyncListTab = true;
          } else {
            window.$bus.emit('asyncJobListRefresh');
            window.$bus.emit('asyncTaskListBack');
          }
          break;
        default:
          break;
      }
      // this.analysisSplit(selectedSql);
    },
    async commit() {
      const data = {
        sessionId: this.tab.sessionId
      };
      const res = await this.$services.commit({ data });
      if (res.success) {
        this.tab.readyToCommit = false;
        this.$message.success('提交成功');
      } else if (res.code === '7002') {
        this.tab.readyToCommit = false;
        this.storeQueryTabs();
      }
    },
    handleCommit() {
      this.commit();
    },
    async rollback() {
      const data = {
        sessionId: this.tab.sessionId
      };
      const res = await this.$services.rollBack({ data });
      if (res.success) {
        this.tab.readyToCommit = false;
        this.$message.success('回滚成功');
      } else if (res.code === '7002') {
        this.tab.readyToCommit = false;
        this.storeQueryTabs();
      }
    },
    handleRollback() {
      this.rollback();
    },
    async cancelQuery() {
      this.stopping = true;
      const data = {
        sessionId: this.tab.sessionId
      };
      const res = await this.$services.cancelQuery({ data });
      if (res.success) {
        this.running = false;
        this.tab.running = false;
        this.updateResultSpining(false);
      }
      this.stopping = false;
    },
    handleStop() {
      this.cancelQuery();
      this.storeQueryTabs();
    },
    setSql(sql) {
      const originSql = this.myCodeMirror.getValue();
      let newSql = '';
      if (originSql) {
        newSql = `${originSql}\n${sql}`;
      } else {
        newSql = sql;
      }
      this.myCodeMirror.setValue(newSql);
      const lastLine = this.myCodeMirror.lastLine();
      this.myCodeMirror.setSelection({
        line: lastLine,
        ch: 0
      }, {
        line: lastLine,
        ch: sql.length
      });
    },
    handleClean() {
      this.myCodeMirror.setValue('');
      this.tab.queryText = this.myCodeMirror.getValue();
      this.storeQueryTabs();
    },
    async changeSchema(db, type) {
      if (!hasSchema(this.tab.dataSourceType) || type !== 'db') {
        let data = {
          sessionId: this.tab.sessionId,
          defaultSchema: db
        };
        if (type === 'schema') {
          data = {
            sessionId: this.tab.sessionId,
            defaultSchema: db,
            defaultDb: this.tab.database
          };
        }
        const res = await this.$services.changeSchema({ data });
        if (res.success) {
          this.tab.sessionId = res.data.sessionId;
          // list tables
          this.listTableForTab(this.tab);
        }
      } else {
        this.listDbSchemasForSelect();
      }
    },
    async listDbSchemasForSelect() {
      const data = {
        dataSourceId: this.tab.instanceId,
        parentData: this.tab.database,
        useVisibility: false
      };
      const res = await this.$services.listDsSchema({ data });
      if (res.success) {
        this.tab.schemaList = res.data;
        this.tab.schema = '';
      }
    },
    handleTabDb(value) {
      this.tab.database = value;
      this.listTableForTab(this.tab);
    },
    handleTabSchema(value) {
      this.tab.schema = value;
      this.listTableForTab(this.tab);
    },
    createWebSocket() {
      try {
        if ('WebSocket' in window) {
          const protocal = document.location.protocol;
          let wsPrex = 'ws';
          if (protocal === 'https:') {
            wsPrex = 'wss';
          }
          this.ws = new WebSocket(`${wsPrex}://${window.location.host}/clouddm/console/api/v1/query_socket`);
        }
      } catch (e) {
        console.log(e);
        this.errorMessage = '创建websocket连接失败，请重试';
      }
    },
    handleCloseError() {
      this.showError = false;
    },
    setReadyToCommit(tab) {
      tab.readyToCommit = false;
      this.storeQueryTabs();
    }
  }
};
</script>
<style lang="less">
.CodeMirror {
  height: 250px !important;
  border-bottom: 1px solid #ddd;
  font-family: monospace;
}

.codemirror-wrapper {
  position: relative;
}

.sql-viewer {
  position: relative;

  .dragBar {
    position: absolute;
    z-index: 3;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #fff;
    cursor: ns-resize;
    border-bottom: 1px solid #ddd;
    border-width: 1px 0;
  }

  .sql-error {
    position: absolute;
    bottom: 5px;
    width: 100%;
    background: #FFDCDC;
    padding: 10px 12px;
    z-index: 4;
    max-height: 200px;
    overflow: auto;

    .sql-error-close-btn {
      position: absolute;
      right: 12px;
      top: 12px;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
</style>
