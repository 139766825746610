<template>
  <div class="edit-fk">
<!--    <div class="op">-->
<!--      <a-button ghost type="primary" @click="addColumn" size="small" style="margin-right: 10px;">新增</a-button>-->
<!--      <a-button ghost @click="removeColumn" size="small">删除</a-button>-->
<!--    </div>-->
    <vxe-grid
      size="mini"
      :columns="columns"
      :data="fkData"
      :height="viewHeight - 25"
    >
    </vxe-grid>
  </div>
</template>

<script>
import deepClone from 'lodash.clonedeep';

export default {
  name: 'EditFK',
  props: {
    fkData: Array,
    viewHeight: Number
  },
  data() {
    return {
      columns: [
        {
          title: '名称',
          field: 'name'
        },
        {
          title: '参考库',
          field: 'referenceSchema'
        },
        {
          title: '参考表',
          field: 'referenceTable'
        },
        {
          title: '参考列',
          slots: {
            default: ({ row }) => Object.keys(row.referenceMapping).map((key) => <div>{key} <span style="color: orange">REFERENCE</span> {row.referenceMapping[key]}</div>)
          },
          render: (h, params) => {
            const columnsRender = [];
            Object.keys(params.row.referenceMapping)
              .map((key) => {
                columnsRender.push(h('p', {}, `${key} REFERENCE ${params.row.referenceMapping[key]}`));
                return null;
              });
            return h('div', columnsRender);
          }
        },
        {
          title: '更新规则',
          field: 'updateRule'
        },
        {
          title: '删除规则',
          field: 'deleteRule'
        }
      ],
      editFkData: [],
      adds: [],
      deletes: []
    };
  },
  methods: {
    addColumn() {
    },
    removeColumn() {
    },
    switchTab() {
      return { data: this.editFkData, deletes: this.deletes, adds: this.adds };
    }
  },
  created() {
    this.editFkData = deepClone(this.fkData);
  }
};
</script>

<style scoped lang="less">
.edit-fk {
  .op {
    padding: 0 5px;
    width: 100%;
    height: 40px;
    border-top: 1px solid #eee;
    display: flex;
    align-items: center;
  }
}
</style>
