<template>
  <div style="display: inline-block" class="datasource-icon">
    <cc-data-source-icon color="#ffffff" background="#0087c7" :size="12" :type="type" :instanceType="instanceType"></cc-data-source-icon>
  </div>
</template>
<script>
export default {
  name: 'DataSourceIcon',
  props: {
    type: String,
    instanceType: String
  }
};
</script>
<style lang="less" scoped>
.datasource-icon {
  background: #0071AF;
  width: 16px;
  height: 16px;
  line-height: 16px;
  margin-right: 4px;
  vertical-align: middle;
}
</style>
