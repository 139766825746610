<template>
  <div class="sql-home-container">
    <div :class="`data-source-container ${closeMenu?'menu-close':'menu-open'}`">
      <div v-if="!closeMenu" style="height: 100%;display: flex;flex-direction: column;">
        <div class="data-source-filter" :style="closeMenu?'display:none':''">
          <a-input v-model="filterDsKey" class="filter-input" @change="handleFilterDs">
            <a-icon slot="prefix" type="search"/>
          </a-input>
          <cc-iconfont style="margin-right: 6px" :size="14" class="icon" color="#999999" name="refresh"
                       @click.native="getAllDs"></cc-iconfont>
          <cc-iconfont @click.native="handleSwitchDsList" :size="14" class="icon" color="#999999"
                       name="shouqi"></cc-iconfont>
        </div>
        <Menu :dataSourceList="dataSourceList" :getDataSourceList="getDataSourceList"
              :handleNewQuery="handleNewDbQuery" :handleShowStruct="handleShowStruct"
              :handleShowData="handleShowData" :key="curentMenu" :getTableList="getTableList"></Menu>
      </div>
      <div v-if="closeMenu" class="closed-menu-list">
        <cc-iconfont @click.native="handleSwitchDsList" :size="14" class="icon" color="#999999"
                     name="zhankai"></cc-iconfont>
      </div>
    </div>
    <div :class="`query-container ${closeMenu?'full-query':'not-full-query'}`">
      <div>
        <a-tabs v-model="active" :animated="false" @change="handleChangeTab" v-if="tabs.length>0"
                hide-add size="small" type="editable-card" class="query-main-body"
                @edit="handleEditTabs">
          <a-tab-pane v-for="(tab) in tabs" :key="tab.key">
            <div slot="tab" class="tab-info-container">
              <a-tooltip v-if="tab.type !== 'struct'" placement="bottom">
                <div slot="title" class="tab-hover-tip">
                  <p class="tab-tip-db">{{tab.database}}{{tab.schema?`.${tab.schema}`:''}}</p>
                  <p class="tab-tip-instance">{{tab.defaultHost}}【{{tab.instanceDesc}}】</p>
                </div>
                <div>
<!--                  <cc-data-source-icon :instanceType="tab.deployEnvType" :size="14" :type="tab.dataSourceType"-->
<!--                                       color="#4DBAEE"/>-->
                  {{ tab.schema ||tab.database || tab.instanceDesc }}
                </div>
              </a-tooltip>
              <a-tooltip v-if="tab.type === 'struct'" placement="bottom"
                         :title="`${tab.database}.${tab.label}`">
                <div>
                  <cc-iconfont :size="14" color="#999999" name="biao"/>
                  {{ tab.label }}
                </div>
              </a-tooltip>
            </div>
            <div>
              <div class="query-content-container">
                <div v-if="tab.type === 'query'">
                  <div class="query-schema-select">
                    <a-select v-if="!noStruct(tab.dataSourceType)" style="width: 600px" @change="handleChangeCurrentDb(tab)"
                              v-model="currentTabData" showSearch placeholder="请选择">
                      <a-select-option v-for="db in hasSchema(currentTab.dataSourceType)?currentTab.schemaList:currentTab.dbList" :key="db.name" :value="db.name">
                        <div class="query-schema-select-value">
                          <cc-data-source-icon style="background: #343434;width: 20px;height: 20px;line-height: 20px;"
                                               :instanceType="currentTab.deployEnvType" :size="18" :type="currentTab.dataSourceType"
                                               color="#ffffff"/>
                          <span>
                            <span class="db-in-select">{{db.name}}</span>@{{currentTab.defaultHost}}【{{currentTab.instanceDesc}}】
                          </span>
                        </div>
                      </a-select-option>
                    </a-select>
                    <span v-if="noStruct(tab.dataSourceType)" class="no-struct-ds-title">
                                                      <cc-data-source-icon style="background: #343434;width: 20px;height: 20px;line-height: 20px;"
                                                                           :instanceType="currentTab.deployEnvType" :size="18" :type="currentTab.dataSourceType"
                                                                           color="#ffffff"/>
                      {{currentTab.defaultHost}}【{{currentTab.instanceDesc}}】
                    </span>
                  </div>
                  <div>
                    <TableList :closeTable="closeTable" :handleCloseTableList="handleCloseTableList"
                               :currentDbOrSchema="currentDbOrSchema"
                               :handleSearchTable="handleSearchTable" :handleRefreshTables="handleRefreshTables"
                               :currentTab="currentTab" :handleNewDbQuery="handleNewDbQuery"
                               :handleSelectTable="handleSelectTable" :handleShowStruct="handleShowStruct"
                               :handleGoTicket="handleGoTicket"
                               :handleChangeTablePage="handleChangeTablePage" :handleTabDb="handleTabDb"
                               :handleTablePageSize="handleTablePageSize"
                               :handleChangeViewPage="handleChangeViewPage" :handleViewPageSize="handleViewPageSize"
                               :spinning="spinning" :currentTable="currentTable"></TableList>
                  </div>
                  <div style="margin-left: 204px;margin-top: 2px">
                    <div class="layout-content-main">
                      <SqlViewer :ref="`codemirror_${tab.key}`" :allDsList="allDsList"
                                 :createSession="createSession" :updateResultSpining="updateResultSpining"
                                 :getEditor="getEditor" :resize="resize" :storeQueryTabs="storeQueryTabs"
                                 :tab="tab" :listTableForTab="listTableForTab"/>
                    </div>
                    <div>
                      <div ref="result" class="result-wrapper">
                        <Result :id="`result_${tab.key}`" :ref="`result_${tab.key}`" :resultList="tab.resultList"
                                :tab="tab" :resultSpining="resultSpining"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="tab.type === 'struct'">
                  <StructView :tab="tab" :refresh-tables="listDbTablesByExpand" :handleEditTabs="handleEditTabs"></StructView>
                </div>
                <div v-else-if="tab.type === 'data'">
                  <data-view :createSession="createSession" :tab="tab"/>
                </div>
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import SqlViewer from '@views/sql/components/SqlViewer.vue';
import Result from '@views/sql/components/Result.vue';
import './style/index.less';
import StructView from '@views/sql/components/struct/StructView.vue';
import DataSourceIcon from '@views/sql/components/DataSourceIcon.vue';
import Menu from '@views/sql/components/Menu.vue';
import { mapState } from 'vuex';
import { Modal } from 'ant-design-vue';
import DataView from '@views/sql/components/DataView.vue';
import deepClone from 'lodash.clonedeep';
import { PG_GP } from '@/consts';
import TableList from '@views/sql/components/TableList.vue';
import { hasSchema, noStruct } from '../../utils/index';

export default {
  name: 'Sql',
  components: {
    DataView,
    SqlViewer,
    Result,
    StructView,
    // eslint-disable-next-line vue/no-unused-components
    DataSourceIcon,
    Menu,
    TableList
  },
  data() {
    return {
      dataSourceList: [],
      tabs: [],
      active: 'tab0',
      editor: '',
      pageHeight: 0,
      allDsList: [],
      filterDsKey: '',
      preRequestData: {},
      currentMethod: '',
      closeMenu: false,
      closeTable: false,
      curentMenu: 0,
      currentTab: {},
      showCount: 20,
      currentDbOrSchema: {},
      currentTable: '',
      spinning: false,
      resultSpining: false,
      hasSchema,
      noStruct
    };
  },
  created() {
    window.$bus.on('sql_listfirstlevel_callback', this.handlePreRequest);
  },
  mounted() {
    document.addEventListener('resize', this.calcHeight);
    this.getAllDs();
    localStorage.removeItem('sqlToTicket');
    localStorage.removeItem('instanceToTicket');
    localStorage.removeItem('databaseToTicket');
    localStorage.removeItem('schemaToTicket');
    localStorage.removeItem('dsTypeToTicket');
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo
    }),
    currentTabData: {
      get() {
        return hasSchema(this.currentTab.dataSourceType) ? this.currentTab.schema : this.currentTab.database;
      },
      set(data) {
        if (hasSchema(this.currentTab.dataSourceType)) {
          this.currentTab.schema = data;
        } else {
          this.currentTab.database = data;
        }
      }
    }
  },
  methods: {
    async getLocalQueryData(queryData) {
      const res = await this.$services.getUserInfo();
      if (res.success) {
        this.tabs = JSON.parse(queryData)[res.data.uid] || [];
        if (this.tabs.length > 0) {
          this.active = this.tabs[0].active;
          let currentIndex = 0;
          this.tabs.forEach((tab, index) => {
            if (tab.key === tab.active) {
              this.currentTab = tab;
              currentIndex = index;
            }
          });
          if (noStruct(this.currentTab.dataSourceType)) {
            this.getRedisKey(this.currentTab);
          } else {
            this.getDbAndTableList(this.tabs[currentIndex].instanceId, this.tabs[currentIndex], this.tabs[currentIndex].database);
          }
        }
      }
    },
    calcHeight() {
      this.pageHeight = window.innerHeight - 40;
    },
    async topKeysWithLimit(tab, callback, searchKey) {
      const res = await this.$services.topKeysWithLimit({
        data: {
          dataSourceId: tab.instanceId,
          pattern: searchKey || '*'
        }
      });
      if (res.success) {
        callback(res.data);
      }
      this.spinning = false;
    },
    async getAutoCommit(tab) {
      const res = await this.$services.getAutoCommit({
        data: {
          sessionId: tab.sessionId
        }
      });
      if (res.success) {
        tab.autoCommit = res.data.autoCommit;
      }
    },
    async listAllDs() {
      const res = await this.$services.getDsList({
        data: {
          lifeCycleState: 'CREATED',
          useVisibility: false
        }
      });
      if (res.success) {
        this.allDsList = res.data;
        this.dataSourceList = [];
        this.allDsList.map((item) => {
          let hasEnv = false;
          this.dataSourceList.map((ds) => {
            if (ds.id === item.dsEnvId) {
              hasEnv = true;
              if (item.dataSourceType === 'Redis') {
                ds.children.push({
                  title: item.instanceId,
                  desc: item.instanceDesc,
                  dsEnvId: item.dsEnvId,
                  type: 'instance',
                  id: item.id,
                  show: true,
                  contextmenu: true,
                  dataSourceType: item.dataSourceType,
                  deployEnvType: item.deployEnvType,
                  defaultHost: item.defaultHost,
                  render: (h) => h('span', {
                    style: {
                      display: 'inline-block',
                      width: '100%'
                    }
                  }, [
                    h(DataSourceIcon, {
                      props: {
                        type: item.dataSourceType,
                        instanceType: item.deployEnvType,
                        iconWidth: 16
                      }
                    }),
                    h('span', {
                      style: {
                        color: '@background-grey'
                      }
                    }, item.instanceDesc)
                    // h('span', {
                    //   style: {
                    //     color: '#888888'
                    //   }
                    // }, `/${item.defaultHost}`)
                  ])
                });
              } else {
                ds.children.push({
                  title: item.instanceId,
                  desc: item.instanceDesc,
                  dsEnvId: item.dsEnvId,
                  loading: false,
                  type: 'instance',
                  children: [],
                  id: item.id,
                  show: true,
                  contextmenu: true,
                  dataSourceType: item.dataSourceType,
                  deployEnvType: item.deployEnvType,
                  defaultHost: item.defaultHost,
                  render: (h) => h('span', {
                    style: {
                      display: 'inline-block',
                      width: '100%'
                    }
                  }, [
                    h(DataSourceIcon, {
                      props: {
                        type: item.dataSourceType,
                        instanceType: item.deployEnvType,
                        iconWidth: 16
                      }
                    }),
                    h('span', {
                      style: {
                        color: '@background-grey'
                      }
                    }, item.instanceDesc)
                    // h('span', {
                    //   style: {
                    //     color: '#888888'
                    //   }
                    // }, `/${item.defaultHost}`)
                  ])
                });
              }
            }
            return null;
          });
          if (!hasEnv) {
            const children = [];
            if (item.dataSourceType === 'Redis') {
              children.push({
                title: item.instanceId,
                desc: item.instanceDesc,
                dsEnvId: item.dsEnvId,
                type: 'instance',
                id: item.id,
                contextmenu: true,
                show: true,
                deployEnvType: item.deployEnvType,
                dataSourceType: item.dataSourceType,
                defaultHost: item.defaultHost,
                render: (h) => h('span', {
                  style: {
                    display: 'inline-block',
                    width: '100%'
                  }
                }, [
                  h(DataSourceIcon, {
                    props: {
                      type: item.dataSourceType,
                      instanceType: item.deployEnvType,
                      iconWidth: 16
                    }
                  }),
                  h('span', {
                    style: {
                      color: '@background-grey'
                    }
                  }, item.instanceDesc)
                  // h('span', {
                  //   style: {
                  //     color: '#888888'
                  //   }
                  // }, `/${item.instanceId}`)
                ])
              });
            } else {
              children.push({
                title: item.instanceId,
                desc: item.instanceDesc,
                dsEnvId: item.dsEnvId,
                loading: false,
                type: 'instance',
                children: [],
                id: item.id,
                contextmenu: true,
                show: true,
                deployEnvType: item.deployEnvType,
                dataSourceType: item.dataSourceType,
                defaultHost: item.defaultHost,
                render: (h) => h('span', {
                  style: {
                    display: 'inline-block',
                    width: '100%'
                  }
                }, [
                  h(DataSourceIcon, {
                    props: {
                      type: item.dataSourceType,
                      instanceType: item.deployEnvType,
                      iconWidth: 16
                    }
                  }),
                  h('span', {
                    style: {
                      color: '@background-grey'
                    }
                  }, item.instanceDesc)
                  // h('span', {
                  //   style: {
                  //     color: '#888888'
                  //   }
                  // }, `/${item.instanceId}`)
                ])
              });
            }
            this.dataSourceList.push({
              title: item.dsEnvName,
              loading: false,
              expand: true,
              show: true,
              type: 'env',
              children,
              id: item.dsEnvId
            });
          }
          return null;
        });
      }
      const queryData = localStorage.getItem('cloudDM_queryData');
      if (queryData) {
        this.getLocalQueryData(queryData);
      }
    },
    getAllDs() {
      this.listAllDs();
    },
    async listFirstLevel(instance, callback) {
      const data = {
        dataSourceId: instance.id,
        useVisibility: false
      };
      // const res1 = await this.$services.groupDsNodes({
      //   data,
      //   path: '/',
      //   fullInfo: true
      // });
      // console.log('res1', res1);
      const res = await this.$services.listSchemaFirstLevel({
        data,
        page: 'sql'
      });
      if (res.success) {
        let callbackData = [];
        let currentDs = {};
        this.dataSourceList.map((env) => {
          env.children.map((ds) => {
            if (ds.id === instance.id) {
              currentDs = ds;
              currentDs.nextStart = this.showCount;
            }
            return null;
          });
          return null;
        });
        this.allDsList.map((item) => {
          if (item.id === instance.id) {
            const dbList = [];
            res.data.nameList.map((db) => {
              dbList.push({
                name: db,
                children: [],
                type: 'db',
                show: true,
                dsEnvId: item.dsEnvId,
                dataSourceType: instance.dataSourceType,
                deployEnvType: instance.deployEnvType
              });
              return null;
            });
            item.dbList = dbList;
          }
          return null;
        });
        if (callback) {
          const childrenList = [];
          res.data.nameList.map((item) => {
            if (res.data.hasNextLevel) {
              childrenList.push({
                title: item,
                type: 'db',
                label: item,
                instance: instance.title,
                instanceId: instance.id,
                instanceDesc: instance.desc,
                defaultHost: instance.defaultHost,
                dsEnvId: instance.dsEnvId,
                contextmenu: true,
                show: true,
                dataSourceType: instance.dataSourceType,
                deployEnvType: instance.deployEnvType,
                children: [],
                loading: false,
                hasSchema: res.data.hasNextLevel,
                render: (h) => h('span', {
                  style: {
                    display: 'inline-block',
                    width: '100%'
                  }
                }, [
                  h('cc-iconfont', {
                    props: {
                      name: 'shujuku',
                      size: 14,
                      color: '#999999'
                    },
                    style: {
                      marginRight: '4px'
                    }
                  }),
                  h('span', {
                    style: {
                      color: '@background-grey'
                    }
                  }, item)
                ])
              });
            } else {
              childrenList.push({
                title: item,
                type: 'db',
                instance: instance.title,
                instanceId: instance.id,
                instanceDesc: instance.desc,
                dsEnvId: instance.dsEnvId,
                defaultHost: instance.defaultHost,
                contextmenu: true,
                show: true,
                dataSourceType: instance.dataSourceType,
                deployEnvType: instance.deployEnvType,
                render: (h) => h('span', {
                  style: {
                    display: 'inline-block',
                    width: '100%'
                  }
                }, [
                  h('cc-iconfont', {
                    props: {
                      name: 'shujuku',
                      size: 14,
                      color: '#999999'
                    },
                    style: {
                      marginRight: '4px'
                    }
                  }),
                  h('span', {
                    style: {
                      color: '@background-grey'
                    }
                  }, item)
                ])
              });
            }
            return null;
          });
          currentDs.childrenList = childrenList;
          callbackData = childrenList.slice(0, this.showCount);
          if (currentDs.childrenList.length > this.showCount) {
            const addMore = {
              title: '',
              type: 'addDb',
              label: '',
              instance: instance.title,
              instanceId: instance.id,
              instanceDesc: instance.desc,
              defaultHost: instance.defaultHost,
              contextmenu: true,
              show: true,
              dataSourceType: instance.dataSourceType,
              deployEnvType: instance.deployEnvType,
              hasSchema: false,
              render: (h) => h('span', {
                style: {
                  display: 'inline-block',
                  width: '100%'
                }
              }, [
                h('cc-iconfont', {
                  props: {
                    name: 'more',
                    size: 14,
                    color: '#999999'
                  },
                  style: {
                    marginRight: '4px'
                  }
                }),
                h('span', {}, '加载更多数据')
              ])
            };
            callbackData.push(addMore);
            currentDs.addMore = addMore;
          }
          callback(callbackData);
        }
      } else {
        instance.loading = false;
        this.preRequestData = {
          instance,
          callback
        };
        this.currentMethod = 'listFirstLevel';
      }
    },
    async listDbSchemas(db, callback) {
      const data = {
        dataSourceId: db.instanceId,
        parentData: db.title || db.name || db.database,
        useVisibility: false
      };
      const res = await this.$services.listDsSchema({ data });
      if (res.success) {
        const childrenList = [];
        let callbackData = [];
        this.allDsList.map((item) => {
          if (item.dbList) {
            item.dbList.map((db1) => {
              if (db1.name === db.title || db1.name === db.name) {
                const schemaList = [];
                res.data.map((schema) => {
                  schemaList.push({
                    name: schema.name,
                    children: [],
                    type: 'schema',
                    show: true,
                    dsEnvId: item.dsEnvId,
                    dataSourceType: item.dataSourceType,
                    deployEnvType: item.deployEnvType
                  });
                  return null;
                });
                item.schemaList = schemaList;
              }
              return null;
            });
          }
          return null;
        });
        res.data.map((item) => {
          childrenList.push({
            title: item.name,
            database: db.title || db.name,
            label: db.title || db.name,
            type: 'schema',
            instance: db.instance,
            instanceId: db.instanceId,
            instanceDesc: db.instanceDesc,
            defaultHost: db.defaultHost,
            contextmenu: true,
            show: true,
            dsEnvId: db.dsEnvId,
            dataSourceType: db.dataSourceType,
            deployEnvType: db.deployEnvType,
            render: (h) => h('span', {
              style: {
                display: 'inline-block',
                width: '100%'
              }
            }, [
              h('cc-iconfont', {
                props: {
                  name: 'schema',
                  size: 14,
                  color: '#999999'
                },
                style: {
                  marginRight: '4px'
                }
              }),
              h('span', {
                style: {
                  color: '@background-grey'
                }
              }, item.name)
            ])
          });
          return null;
        });
        callbackData = childrenList.slice(0, this.showCount);
        db.childrenList = childrenList;
        db.nextStart = this.showCount;
        if (childrenList.length > this.showCount) {
          const addMore = {
            title: '',
            database: db.title || db.name,
            label: db.title || db.name,
            type: 'addSchema',
            instance: db.instance,
            instanceId: db.instanceId,
            instanceDesc: db.instanceDesc,
            defaultHost: db.defaultHost,
            contextmenu: true,
            show: true,
            dsEnvId: db.dsEnvId,
            dataSourceType: db.dataSourceType,
            deployEnvType: db.deployEnvType,
            render: (h) => h('span', {
              style: {
                display: 'inline-block',
                width: '100%'
              }
            }, [
              h('cc-iconfont', {
                props: {
                  name: 'more',
                  size: 14,
                  color: '#999999'
                },
                style: {
                  marginRight: '4px'
                }
              }),
              h('span', {
                style: {
                  color: '#888888'
                },
                on: {
                  click: () => {
                    db.children = db.childrenList.slice(0, db.nextStart + this.showCount);
                    db.nextStart += this.showCount;
                    if (db.childrenList.length > db.nextStart) {
                      db.children.push(db.addMore);
                    }
                  }
                }
              }, '加载更多数据')
            ])
          };
          callbackData.push(addMore);
          db.addMore = addMore;
        }
        if (callback) {
          callback(callbackData);
        }
      }
    },
    async listDbTables(db, tab, callback, schema) {
      let data = {
        dataSourceId: db.instanceId,
        parentData: '',
        schemaName: db.title || db.name,
        useVisibility: false
      };
      if (schema) {
        data = {
          dataSourceId: db.instanceId,
          parentData: hasSchema(db.dataSourceType) ? db.title || db.name || db.database : '',
          schemaName: schema,
          useVisibility: false
        };
      }
      const res = await this.$services.groupTables({ data });
      if (res.success) {
        tab.tables = res.data.TABLE || [];
        tab.views = res.data.VIEW || [];
        tab.filteredTableList = deepClone(tab.tables) || [];
        tab.filteredViewList = deepClone(tab.views) || [];
        if (!tab.pageData) {
          tab.pageData = {
            page: 1,
            size: 100
          };
        }
        tab.showTableList = tab.filteredTableList.slice(0, tab.pageData.size);
        tab.showViewList = tab.filteredViewList.slice(0, tab.pageData.size);
        tab.currentDs = {};
        tab.currentDs.instanceId = db.instanceId;
        tab.tableList = res.data.TABLE || [];
        tab.viewList = res.data.VIEW || [];
        tab.currentDs.db = db.title || db.name;
        this.dataSourceList.map((env) => {
          if (tab.dsEnvId === env.id) {
            env.children.map((ds) => {
              if (ds.id === tab.instanceId) {
                if (ds.children && ds.children instanceof Array) {
                  ds.children.map((db1) => {
                    if (db1.title === tab.database) {
                      if (db1.hasSchema) {
                        if (db1.children && db1.children instanceof Array) {
                          db1.children.map((schema1) => {
                            if (schema1.title === tab.schema) {
                              schema1.tableList = res.data.TABLE || [];
                              schema1.viewList = res.data.VIEW || [];
                            }
                            return null;
                          });
                        }
                      } else {
                        db1.tableList = res.data.TABLE || [];
                        db1.viewList = res.data.VIEW || [];
                      }
                    }
                    return null;
                  });
                }
              }
              return null;
            });
          }
          return null;
        });
        this.tabs.push('');
        this.tabs.pop();
        if (callback) {
          callback(res.data);
        }
      }
    },
    async listTableForTab(tab) {
      let data = {
        dataSourceId: tab.instanceId,
        parentData: '',
        schemaName: tab.database,
        useVisibility: false
      };
      if (tab.schema) {
        data = {
          dataSourceId: tab.instanceId,
          parentData: tab.database,
          schemaName: tab.schema,
          useVisibility: false
        };
      }
      const res = await this.$services.groupTables({ data });
      if (res.success) {
        tab.tableList = (res.data.TABLE || []).concat(res.data.VIEW || []);
      }
    },
    async listDbTablesByExpand(ds, callback) {
      if (ds.type === 'db' || ds.type === 'schema' || ds.type === 'query') {
        this.spinning = true;
        let data = {
          dataSourceId: ds.instanceId,
          parentData: hasSchema(ds.dataSourceType) ? ds.database : '',
          schemaName: hasSchema(ds.dataSourceType) ? ds.schema : ds.title || ds.name || ds.database,
          useVisibility: false
        };
        if (ds.type === 'schema') {
          data = {
            dataSourceId: ds.instanceId,
            parentData: ds.database,
            schemaName: ds.title,
            useVisibility: false
          };
        }
        const res = await this.$services.groupTables({ data });
        if (res.success) {
          ds.tables = res.data.TABLE || [];
          ds.views = res.data.VIEW || [];
          ds.filteredTableList = deepClone(ds.tables) || [];
          ds.filteredViewList = deepClone(ds.views) || [];
          if (!ds.pageData) {
            ds.pageData = {
              page: 1,
              size: 100
            };
          }
          ds.showTableList = ds.filteredTableList.slice(0, ds.pageData.size);
          ds.showViewList = ds.filteredViewList.slice(0, ds.pageData.size);
          ds.tableList = res.data.TABLE || [];
          ds.viewList = res.data.VIEW || [];
          if (callback) {
            callback(res.data);
          }
        }
        this.spinning = false;
      }
    },
    getDataSourceList(item, callback) {
      if (item.type === 'instance') {
        this.listFirstLevel(item, callback);
      } else if (item.hasSchema) {
        this.listDbSchemas(item, callback);
      } else {
        this.listDbTables(item, callback);
      }
    },
    async closesSession(tab) {
      if (tab.sessionId) {
        const data = {
          sessionId: tab.sessionId
        };
        await this.$services.closesSession({ data });
      }
    },
    handleEditTabs(targetKey, action) {
      // let type = '';
      // if (targetKey.startsWith('tab_struct') || targetKey.startsWith('tab_data')) {
      //   type = 'child';
      // }
      if (action === 'remove') {
        this.removeTab('', targetKey);
      }
    },
    removeTab(type, name) {
      const localData = localStorage.getItem('cloudDM_queryData');
      if (type === 'child') {
        this.tabs.forEach((tab, index) => {
          tab.children.forEach((table, index2) => {
            if (table.key === name) {
              this.tabs[index].children.splice(index2, 1);
              if (this.tabs[index].children.length > 0) {
                this.tabs[index].active = this.tabs[index].children[0].key;
              }
              if (localData) {
                const localList = JSON.parse(localData);
                localList.forEach((tab2, index3) => {
                  tab2.children.forEach((table2, index4) => {
                    if (table2.key === name) {
                      localList[index3].children.splice(index4, 1);
                    }
                  });
                });
                let data = {};
                if (localStorage.getItem('cloudDM_queryData')) {
                  data = JSON.parse(localStorage.getItem('cloudDM_queryData'));
                }
                data[this.userInfo.uid] = localList;
                localStorage.setItem('cloudDM_queryData', JSON.stringify({}));
              }
            }
          });
        });
      } else {
        this.tabs.map((tab, index) => {
          if (tab.key === name) {
            this.tabs.splice(index, 1);
            if (localData) {
              const localList = JSON.parse(localData)[this.userInfo.uid];
              if (localList && localList instanceof Array) {
                localList.map((item, i) => {
                  if (item.key === tab.key) {
                    localList.splice(i, 1);
                  }
                  return null;
                });
              }
              let data = {};
              if (localStorage.getItem('cloudDM_queryData')) {
                data = JSON.parse(localStorage.getItem('cloudDM_queryData'));
              }
              const tabData = deepClone(localList);
              tabData.forEach((tab1) => {
                tab1.dbList = [];
                tab1.tableList = [];
                tab1.tables = [];
                tab1.showTableList = [];
                tab1.showViewList = [];
                tab1.viewList = [];
                tab1.views = [];
                tab1.filteredTableList = [];
                tab1.filteredViewList = [];
                tab1.resultList = [];
                tab1.executeInfo = [];
              });
              data[this.userInfo.uid] = tabData;
              localStorage.setItem('cloudDM_queryData', JSON.stringify(data));
            }
            this.closesSession(tab);
          }
          if (this.tabs.length > 0) {
            this.active = this.tabs[0].key;
            this.handleChangeTab(this.tabs[0].key);
          }
          return null;
        });
      }
    },
    getEditor(cm) {
      this.editor = cm;
    },
    async createSession(tab, next) {
      let data = {
        dataSourceId: tab.instanceId,
        defaultSchema: tab.database
      };
      if (hasSchema(tab.dataSourceType)) {
        data = {
          dataSourceId: tab.instanceId,
          defaultSchema: tab.schema,
          parentData: tab.database
        };
      }
      const res = await this.$services.createSession({
        data,
        page: 'sql'
      });
      if (res.success) {
        this.tabs.map((item) => {
          if (item.key === tab.key) {
            item.sessionId = res.data.sessionId;
            if (item.children) {
              item.children.forEach((t) => {
                t.sessionId = res.data.sessionId;
              });
            }
          }
          return null;
        });
        if (next) {
          next();
        }
        this.storeQueryTabs();
      } else {
        this.preRequestData = {
          tab
        };
        this.currentMethod = 'creatession';
        // if (res.code === '5004' && !next) {
        this.tabs.map((tb, index) => {
          if (tb.key === this.active) {
            this.tabs.splice(index, 1);
          }
          return null;
        });
        this.active = this.tabs[this.tabs.length - 1].key;
        // }
      }
    },
    handleNewDbQuery(data, table) {
      if (data.type === 'instance') {
        if (noStruct(data.dataSourceType)) {
          const tab = {
            key: `tab_${data.title}_${new Date().getTime()}`,
            label: data.title,
            instance: data.title,
            database: '',
            instanceId: data.id,
            instanceDesc: data.desc,
            databaseId: '',
            dataSourceType: data.dataSourceType,
            deployEnvType: data.deployEnvType,
            dsEnvId: data.dsEnvId,
            tables: [],
            filteredTableList: [],
            showTableList: [],
            currentDs: {},
            tableList: [],
            tableSearchKey: '',
            currentTable: '',
            queryTabNum: 1,
            type: 'query',
            resultList: [],
            autoCommit: true,
            readyToCommit: false,
            executeInfo: [],
            active: `tab_${data.title}_${new Date().getTime()}`,
            defaultHost: data.defaultHost,
            pageData: {
              page: 1,
              size: 100
            },
            asyncListTab: false
          };
          this.tabs.push(tab);
          this.active = this.tabs[this.tabs.length - 1].key;
          this.tabs.forEach((item) => {
            item.active = this.active;
          });
          this.currentTab = this.tabs[this.tabs.length - 1];
          this.currentDbOrSchema = this.tabs[this.tabs.length - 1];
          this.createSession(this.tabs[this.tabs.length - 1]);
          this.getRedisKey(tab);
        } else if (!hasSchema(data.dataSourceType)) {
          const tab = {
            asyncListTab: false,
            key: `tab_${data.title}_${new Date().getTime()}`,
            label: data.title,
            instance: data.title,
            database: '',
            instanceId: data.id,
            instanceDesc: data.desc,
            databaseId: '',
            dataSourceType: data.dataSourceType,
            deployEnvType: data.deployEnvType,
            dsEnvId: data.dsEnvId,
            tables: [],
            filteredTableList: [],
            showTableList: [],
            currentDs: {},
            tableList: [],
            tableSearchKey: '',
            currentTable: '',
            queryTabNum: 1,
            type: 'query',
            resultList: [],
            autoCommit: true,
            readyToCommit: false,
            executeInfo: [],
            active: `tab_${data.title}_${new Date().getTime()}`,
            defaultHost: data.defaultHost,
            pageData: {
              page: 1,
              size: 100
            }
          };
          if (data.childrenList) {
            data.childrenList.map((item) => {
              item.name = item.title;
              return null;
            });
            tab.dbList = data.childrenList;
            this.tabs.push(tab);
            this.active = this.tabs[this.tabs.length - 1].key;
            this.tabs.forEach((item) => {
              item.active = this.active;
            });
            this.currentTab = this.tabs[this.tabs.length - 1];
            this.currentDbOrSchema = this.tabs[this.tabs.length - 1];
            this.createSession(this.tabs[this.tabs.length - 1]);
          } else {
            const callback = (data1) => {
              data1.map((item) => {
                item.name = item.title;
                return null;
              });
              tab.dbList = data1;
              this.tabs.push(tab);
              this.active = this.tabs[this.tabs.length - 1].key;
              this.tabs.forEach((item) => {
                item.active = this.active;
              });
              this.currentTab = this.tabs[this.tabs.length - 1];
              this.currentDbOrSchema = this.tabs[this.tabs.length - 1];
              this.createSession(this.tabs[this.tabs.length - 1]);
            };
            this.listFirstLevel(data, callback);
          }
        }
      } else if (data.type === 'db') {
        let hasSame = false;
        this.tabs.map((item) => {
          if (item.instance === data.instance && item.database === data.title) {
            hasSame = true;
            this.active = item.key;
            this.currentTab = item;
          }
          return null;
        });
        if (!hasSame) {
          if (this.tabs.length >= 10) {
            Modal.warning({
              title: '窗口上限提示',
              content: '你开启的窗口数量已达上限，请关闭其他窗口后重新操作。',
              okText: '确定'
            });
            return;
          }
          this.tabs.push({
            asyncListTab: false,
            key: `tab_${data.instanceId}_${data.title}`,
            label: data.title,
            instance: data.instance,
            database: data.title,
            instanceId: data.instanceId,
            defaultHost: data.defaultHost,
            instanceDesc: data.instanceDesc,
            databaseId: data.databaseId,
            dataSourceType: data.dataSourceType,
            deployEnvType: data.deployEnvType,
            dsEnvId: data.dsEnvId,
            tables: [],
            filteredTableList: [],
            showTableList: [],
            currentDs: {},
            tableList: [],
            tableSearchKey: '',
            currentTable: '',
            queryTabNum: 1,
            type: 'query',
            resultList: [],
            autoCommit: true,
            readyToCommit: false,
            executeInfo: [],
            active: `tab_${data.instanceId}_${data.title}`,
            pageData: {
              page: 1,
              size: 100
            },
            children: [
              {
                key: `tab_${data.instanceId}_${data.title}.query_1`,
                label: data.title,
                type: 'query',
                structType: 'basic',
                resultList: [],
                autoCommit: true,
                readyToCommit: false,
                executeInfo: [],
                dataSourceType: data.dataSourceType,
                database: data.title,
                dsEnvId: data.dsEnvId
              }
            ]
          });
          this.active = `tab_${data.instanceId}_${data.title}`;
          this.tabs.forEach((item) => {
            item.active = this.active;
          });
          this.currentTab = this.tabs[this.tabs.length - 1];
          this.createSession(this.tabs[this.tabs.length - 1]);
          this.getDbAndTableList(data.instanceId, this.tabs[this.tabs.length - 1], data.title);
        } else {
          this.getDbAndTableList(data.instanceId, this.tabs[this.tabs.length - 1], data.title);
        }
      } else if (data.type === 'schema') {
        let hasSame = false;
        this.tabs.map((item) => {
          if (item.instance === data.instance && item.database === data.database && item.schema === data.title) {
            hasSame = true;
            this.active = item.key;
            this.currentTab = item;
          }
          return null;
        });
        if (!hasSame) {
          if (this.tabs.length >= 10) {
            Modal.warning({
              title: '窗口上限提示',
              content: '你开启的窗口数量已达上限，请关闭其他窗口后重新操作。',
              okText: '确定'
            });
            return;
          }
          this.tabs.push({
            asyncListTab: false,
            key: `tab_${data.instanceId}_${data.database}_${data.title}`,
            label: `${data.database}.${data.title}`,
            instance: data.instance,
            database: data.database,
            schema: data.title,
            instanceId: data.instanceId,
            instanceDesc: data.instanceDesc,
            databaseId: data.databaseId,
            dataSourceType: data.dataSourceType,
            deployEnvType: data.deployEnvType,
            dsEnvId: data.dsEnvId,
            tables: [],
            tableList: [],
            filteredTableList: [],
            showTableList: [],
            currentDs: {},
            tableSearchKey: '',
            currentTable: '',
            queryTabNum: 1,
            type: 'query',
            resultList: [],
            autoCommit: true,
            readyToCommit: false,
            executeInfo: [],
            defaultHost: data.defaultHost,
            active: `tab_${data.instanceId}_${data.database}_${data.title}`,
            pageData: {
              page: 1,
              size: 100
            }
          });
          this.active = `tab_${data.instanceId}_${data.database}_${data.title}`;
          this.tabs.forEach((item) => {
            item.active = this.active;
          });
          this.currentTab = this.tabs[this.tabs.length - 1];
          this.createSession(this.tabs[this.tabs.length - 1]);
          this.getDbAndTableList(data.instanceId, this.tabs[this.tabs.length - 1], data.title);
        } else {
          this.getDbAndTableList(data.instanceId, this.tabs[this.tabs.length - 1], data.title);
        }
      }
      if (table) {
        this.handleQueryTable(table, this.currentTab);
      }
    },
    handleQueryTable(table, tab) {
      let sql = `SELECT * FROM ${table} LIMIT 20;`;
      if (noStruct(tab.dataSourceType)) {
        sql = `GET ${table}`;
      }
      const refname = `codemirror_${tab.key}`;
      tab.currentTable = table;
      this.currentTable = table;
      this.tabs.push('');
      this.tabs.pop();
      setTimeout(() => {
        const editor = this.$refs[refname];
        editor[0].setSql(sql);
      }, 200);
    },
    handleSelectTable(table, tab) {
      this.currentTable = table;
      if (tab) {
        tab.currentTable = table;
        this.tabs.push('');
        this.tabs.pop();
      }
    },
    getDbAndTableList(data, tab, schema) {
      this.allDsList.map((item) => {
        if (item.id === data) {
          if (item.dbList) {
            tab.dbList = item.dbList;
            item.dbList.map((db) => {
              if (db.name === tab.database) {
                db.instanceId = item.id;
                db.title = db.name;
                const callback = (tableData) => {
                  tab.tableList = (tableData.TABLE || []).concat(tableData.VIEW || []);
                  this.currentDbOrSchema = tab;
                };
                if (!hasSchema(tab.dataSourceType) || schema) {
                  this.listDbTables(db, tab, callback, schema);
                }
              }
              return null;
            });
            if (hasSchema(tab.dataSourceType)) {
              const schemaCallback = (schemaCallbackData) => {
                const schemaList = [];
                schemaCallbackData.map((schema1) => {
                  if (schema1.title) {
                    schemaList.push({
                      name: schema1.title,
                      children: [],
                      type: 'schema',
                      show: true,
                      dsEnvId: item.dsEnvId,
                      dataSourceType: tab.dataSourceType,
                      deployEnvType: tab.deployEnvType
                    });
                  }
                  return null;
                });
                tab.schemaList = schemaList;
                this.currentDbOrSchema = tab;
                const tableCallback = (tableCallbackData) => {
                  tab.tableList = (tableCallbackData.TABLE || []).concat(tableCallbackData.VIEW || []);
                  this.currentDbOrSchema = tab;
                };
                if (tab.schema) {
                  this.listDbTables(tab, tab, tableCallback, tab.schema);
                }
              };
              this.listDbSchemas(tab, schemaCallback);
            }
          } else {
            const callback = (callbackData) => {
              const dbList = [];
              callbackData.map((db) => {
                if (db.title) {
                  dbList.push({
                    name: db.title,
                    children: [],
                    type: 'db',
                    show: true,
                    dsEnvId: item.dsEnvId,
                    dataSourceType: tab.dataSourceType,
                    deployEnvType: tab.deployEnvType
                  });
                }
                return null;
              });
              tab.dbList = dbList;
              if (hasSchema(tab.dataSourceType)) {
                const schemaCallback = (schemaCallbackData) => {
                  const schemaList = [];
                  schemaCallbackData.map((schema1) => {
                    if (schema1.title) {
                      schemaList.push({
                        name: schema1.title,
                        children: [],
                        type: 'schema',
                        show: true,
                        dsEnvId: item.dsEnvId,
                        dataSourceType: tab.dataSourceType,
                        deployEnvType: tab.deployEnvType
                      });
                    }
                    return null;
                  });
                  tab.schemaList = schemaList;
                  const tableCallback = (tableCallbackData) => {
                    tab.tableList = (tableCallbackData.TABLE || []).concat(tableCallbackData.VIEW || []);
                    this.currentDbOrSchema = tab;
                  };
                  if (tab.schema) {
                    this.listDbTables(tab, tab, tableCallback, tab.schema);
                  }
                };
                this.listDbSchemas(tab, schemaCallback);
              } else {
                this.getDbAndTableList(data, tab, tab.currentDs.db);
              }
            };
            this.listFirstLevel({ id: data }, callback);
          }
          if (item.schemaList) {
            tab.schemaList = item.schemaList;
          }
        }
        return null;
      });
    },
    handleShowStruct(theTab, table) {
      if (this.tabs.length >= 10) {
        Modal.warning({
          title: '窗口上限提示',
          content: '你开启的窗口数量已达上限，请关闭其他窗口后重新操作。',
          okText: '确定'
        });
        return;
      }
      let hasSame = false;
      const tabKey = PG_GP.includes(this.currentDbOrSchema.dataSourceType)
        ? `tab_struct_${this.currentDbOrSchema.instanceId}_${this.currentDbOrSchema.database}_${this.currentDbOrSchema.schema}_${table.name}`
        : `tab_struct_${this.currentDbOrSchema.instanceId}_${this.currentDbOrSchema.database}_${table.name}`;

      this.tabs.map((item) => {
        if (item.key === tabKey) {
          hasSame = true;
          this.active = item.key;
          this.currentTab = item;
        }
        return null;
      });

      if (!hasSame) {
        this.tabs.push({
          key: tabKey,
          catalog: PG_GP.includes(this.currentDbOrSchema.dataSourceType) ? this.currentDbOrSchema.schema : '',
          type: 'struct',
          currentDbOrSchema: this.currentDbOrSchema,
          structType: 'basic',
          selectedTable: table.name,
          instanceId: this.currentDbOrSchema.instanceId,
          dataSourceType: this.currentDbOrSchema.dataSourceType,
          db: this.currentDbOrSchema.database,
          database: this.currentDbOrSchema.database,
          label: `${table.name}的表结构`,
          tables: [],
          filteredTableList: [],
          pageData: {
            page: 1,
            size: 100
          },
          showTableList: []
        });
        this.active = tabKey;
        this.createSession(this.tabs[this.tabs.length - 1]);
        this.currentTab = this.tabs[this.tabs.length - 1];
      }
    },
    handleShowData(theTab, table) {
      let currentTabIndex = 0;
      this.tabs.map((tab, index) => {
        if (tab.key === theTab.key) {
          currentTabIndex = index;
          tab.children.forEach((t, index2) => {
            if (t.key === `tab_data_${theTab.instanceId}_${theTab.database}_${table.name}`) {
              this.tabs[index].children.splice(index2, 1);
            }
          });
        }
        return null;
      });
      if (this.tabs[currentTabIndex].children.length >= 10) {
        Modal.warning({
          title: '窗口上限提示',
          content: '你开启的窗口数量已达上限，请关闭其他窗口后重新操作。',
          okText: '确定'
        });
        return;
      }
      this.tabs[currentTabIndex].children.push({
        key: `tab_data_${theTab.instanceId}_${theTab.database}_${table.name}`,
        sessionId: this.tabs[currentTabIndex].sessionId,
        type: 'data',
        structType: 'basic',
        selectedTable: table.name,
        instanceId: theTab.instanceId,
        db: theTab.database,
        database: theTab.database,
        dataSourceType: this.tabs[currentTabIndex].dataSourceType
      });
      this.tabs[currentTabIndex].active = `tab_data_${theTab.instanceId}_${theTab.database}_${table.name}`;
    },
    storeQueryTabs() {
      let data = {};
      if (localStorage.getItem('cloudDM_queryData')) {
        data = JSON.parse(localStorage.getItem('cloudDM_queryData'));
      }
      const tabData = deepClone(this.tabs);
      tabData.forEach((tab) => {
        tab.dbList = [];
        tab.tableList = [];
        tab.tables = [];
        tab.showTableList = [];
        tab.showViewList = [];
        tab.viewList = [];
        tab.views = [];
        tab.filteredTableList = [];
        tab.filteredViewList = [];
        tab.resultList = [];
        tab.executeInfo = [];
        tab.asyncListTab = false;
        tab.schemaList = [];
        tab.childrenList = [];
      });
      data[this.userInfo.uid] = tabData;
      localStorage.setItem('cloudDM_queryData', JSON.stringify(data));
    },
    resize(pixel, key) {
      const obj = document.getElementById(`codemirror${key}`)
        .querySelector('.CodeMirror');
      const oldHeight = parseInt(window.getComputedStyle(obj, null).height, 10);

      if (oldHeight < 80 && pixel < 0) {
        return false;
      }
      obj.style = `height:${oldHeight + pixel}px!important`;
      this.$emit('resizeResult', pixel);
      this.resizeResult(pixel, key);
      return null;
    },
    resizeResult(pixel, key) {
      const obj = document.getElementById(`result_${key}`);
      const oldHeight = parseInt(window.getComputedStyle(obj, null).height, 10);
      this.$refs[`result_${key}`][0].pageHeight = oldHeight - pixel;
    },
    handleFilterDs() {
      this.filterTree(this.dataSourceList, this.validate);
    },
    filterTree(data, validate) {
      data.map((env) => {
        let hasDsShow = false;
        env.children.map((ds) => {
          let hasDbShow = false;
          if (ds.childrenList) {
            ds.childrenList.map((db) => {
              let hasSchemaShow = false;
              if (db.childrenList) {
                db.childrenList.map((schema) => {
                  if (!validate(schema)) {
                    schema.show = false;
                  } else {
                    schema.show = true;
                    hasSchemaShow = true;
                  }
                  return null;
                });
                db.children = this.sliceData(db.childrenList, db.addMore);
              }
              if (!validate(db)) {
                if (hasSchemaShow) {
                  db.show = true;
                  hasDbShow = true;
                } else {
                  db.show = false;
                }
              } else {
                db.show = true;
                hasDbShow = true;
              }
              return null;
            });
            ds.children = this.sliceData(ds.childrenList, ds.addMore);
          }
          if (!validate(ds)) {
            if (hasDbShow) {
              ds.show = true;
              hasDsShow = true;
            } else {
              ds.show = false;
            }
          } else {
            ds.show = true;
            hasDsShow = true;
          }
          return null;
        });
        if (!validate(env)) {
          if (hasDsShow) {
            env.show = true;
          } else {
            env.show = false;
          }
        } else {
          env.show = true;
        }
        return null;
      });
    },
    sliceData(dsList, addMore) {
      const data = [];
      let hasMore = false;
      dsList.map((ds) => {
        if (ds.show && data.length < this.showCount) {
          data.push(ds);
        } else if (data.length === this.showCount) {
          hasMore = true;
        }
        return null;
      });
      if (hasMore) {
        data.push(addMore);
      }
      return data;
    },
    // filterTree(nodes, predicate) {
    //   // 如果已经没有节点了，结束递归
    //   if (!(nodes && nodes.length)) {
    //     return [];
    //   }
    //
    //   const newChildren = [];
    //   for (const node of nodes) {
    //     if (predicate(node)) {
    //       // 如果自己（节点）符合条件，直接加入到新的节点集
    //       node.show = true;
    //       newChildren.push(node);
    //       // 并接着处理其 children
    //       if (this.filterTree(node.childrenList || node.children, predicate).length > 0) {
    //         const childrenList = this.filterTree(node.childrenList || node.children, predicate);
    //         console.log('childrenList', childrenList);
    //         node.children = childrenList.slice(0, this.showCount);
    //         console.log('node.children2333', node.children);
    //         if (childrenList.length > this.showCount) {
    //           node.children.push(node.addMore);
    //         }
    //       }
    //     } else {
    //       // 如果自己不符合条件，需要根据子集来判断它是否将其加入新节点集
    //       // 根据递归调用 deal() 的返回值来判断
    //       const subs = this.filterTree(node.childrenList || node.children, predicate);
    //       if (subs && subs.length && this.showSub(subs)) {
    //         node.show = true;
    //       } else {
    //         node.show = false;
    //       }
    //       // 1. 如果子孙集中有符合要求的节点（返回 [...])，加入
    //       if (subs.length > 0) {
    //         node.children = subs;
    //         if (subs[0].type === 'db') {
    //           console.log('subs', node.children, node);
    //         }
    //         if (subs.length > this.showCount) {
    //           node.children.push(node.addMore);
    //         }
    //       }
    //       newChildren.push(node);
    //       // 2. 否则，不加入（因为整个子集都没有符合条件的）
    //     }
    //   }
    //   // eslint-disable-next-line no-void
    //   return newChildren.length ? newChildren : void 0;
    // },

    validate(item) {
      if (item.title.toString()
        .indexOf(this.filterDsKey) > -1) {
        return true;
      }
      return false;
    },
    showSub(subs) {
      let ifShow = false;
      subs.map((item) => {
        if (item.show) {
          ifShow = true;
        }
        return null;
      });
      return ifShow;
    },
    handlePreRequest() {
      if (this.currentMethod === 'listFirstLevel') {
        const {
          instance,
          callback
        } = this.preRequestData;
        this.listFirstLevel(instance, callback);
      } else if (this.currentMethod === 'creatession') {
        const {
          tab
        } = this.preRequestData;
        this.createSession(tab);
      }
    },
    handleSearchTable(tab, type) {
      if (type === 'TABLE') {
        if (!this.currentDbOrSchema.tableSearchKey) {
          this.currentDbOrSchema.filteredTableList = deepClone(this.currentDbOrSchema.tables);
          this.currentDbOrSchema.showTableList = this.currentDbOrSchema.filteredTableList.slice(0, this.currentDbOrSchema.pageData.size);
        } else {
          const list = [];
          this.currentDbOrSchema.pageData.page = 1;
          this.currentDbOrSchema.tables.map((item) => {
            if (item.name.indexOf(this.currentDbOrSchema.tableSearchKey) > -1) {
              list.push(item);
            }
            return null;
          });
          this.currentDbOrSchema.filteredTableList = list;
          this.currentDbOrSchema.showTableList = this.currentDbOrSchema.filteredTableList.slice(0, this.currentDbOrSchema.pageData.size);
        }
      } else if (type === 'VIEW') {
        if (!this.currentDbOrSchema.tableSearchKey) {
          this.currentDbOrSchema.filteredViewList = deepClone(this.currentDbOrSchema.views) || [];
          this.currentDbOrSchema.showViewList = this.currentDbOrSchema.filteredViewList.slice(0, this.currentDbOrSchema.pageData.size);
        } else {
          const list = [];
          this.currentDbOrSchema.pageData.page = 1;
          this.currentDbOrSchema.views.map((item) => {
            if (item.name.indexOf(this.currentDbOrSchema.tableSearchKey) > -1) {
              list.push(item);
            }
            return null;
          });
          this.currentDbOrSchema.filteredViewList = list;
          this.currentDbOrSchema.showViewList = this.currentDbOrSchema.filteredViewList.slice(0, this.currentDbOrSchema.pageData.size);
        }
      } else if (type === 'KEY') {
        this.getRedisKey(this.currentDbOrSchema, this.currentDbOrSchema.tableSearchKey);
      }
    },
    handleChangeTablePage(type, tab) {
      if (type === 'pre') {
        tab.pageData.page--;
      } else if (type === 'next') {
        tab.pageData.page++;
      }
      tab.showTableList = tab.filteredTableList.slice((tab.pageData.page - 1) * tab.pageData.size, tab.pageData.page * tab.pageData.size);
    },
    handleChangeViewPage(type, tab) {
      if (type === 'pre') {
        tab.viewPageData.page--;
      } else if (type === 'next') {
        tab.viewPageData.page++;
      }
      tab.showViewList = tab.filteredViewList.slice((tab.viewPageData.page - 1) * tab.viewPageData.size, tab.viewPageData.page * tab.viewPageData.size);
    },
    handleTablePageSize(tab, data) {
      tab.pageData.size = data;
      tab.pageData.page = 1;
      tab.showTableList = tab.filteredTableList.slice((tab.pageData.page - 1) * tab.pageData.size, tab.pageData.page * tab.pageData.size);
    },
    handleViewPageSize(tab, data) {
      tab.viewPageData.size = data;
      tab.viewPageData.page = 1;
      tab.showViewList = tab.filteredViewList.slice((tab.viewPageData.page - 1) * tab.viewPageData.size, tab.viewPageData.page * tab.viewPageData.size);
    },
    handleInstanceChange() {

    },
    handleCloseTableList() {
      this.closeTable = !this.closeTable;
    },
    handleTabDb(tab, db) {
      this.closesSession(tab);
      this.createSession(tab);
      tab.db = db;
      tab.database = db;
      tab.key = `tab${tab.instanceId}${tab.database}`;
      this.listDbTables({
        instanceId: tab.instanceId,
        title: tab.database
      }, tab, (data) => {
        tab.tables = data;
      });
      this.active = tab.key;
      const that = this;
      setTimeout(() => {
        that.$refs[`codemirror_${tab.key}`][0].handleClean();
      }, 200);
    },
    handleSwitchDsList() {
      this.closeMenu = !this.closeMenu;
    },
    handleGoTicket() {
      localStorage.setItem('instanceToTicket', this.currentDbOrSchema.instanceId);
      localStorage.setItem('databaseToTicket', this.currentDbOrSchema.database);
      localStorage.setItem('schemaToTicket', this.currentDbOrSchema.schema);
      localStorage.setItem('dsTypeToTicket', this.currentDbOrSchema.dataSourceType);
      this.$router.push({
        path: '/ticket_create'
      });
    },
    handleChangeTab(key) {
      let tab = {};
      this.tabs.map((item) => {
        if (item.key === key) {
          tab = item;
        }
        item.active = key;
        return null;
      });
      this.currentTab = tab;
      this.currentDbOrSchema = tab;
      if (!noStruct(tab.dataSourceType)) {
        if (!tab.dbList || !tab.tableList || tab.dbList.length === 0 || tab.tableList.length === 0) {
          this.getDbAndTableList(tab.instanceId, tab, tab.database);
        }
      } else {
        this.getRedisKey(tab);
      }
      this.storeQueryTabs();
      // console.log('datasourcerlist', this.dataSourceList, tab);
      // this.dataSourceList.forEach((env) => {
      //   console.log('env', env);
      //   if (tab.dsEnvId === env.id) {
      //     if (env.children) {
      //       env.children.forEach((ds) => {
      //         console.log('ds', ds);
      //         // if (tab.database) {
      //         //   ds.selected = false;
      //         // }
      //         ds.selected = false;
      //         if (tab.instanceId === ds.id) {
      //           if (ds.children) {
      //             ds.children.forEach((db) => {
      //               if (tab.database === db.title) {
      //                 db.selected = true;
      //                 console.log('hello', db);
      //               } else {
      //                 console.log('db', db);
      //                 db.selected = false;
      //               }
      //             });
      //           }
      //         }
      //       });
      //     }
      //   }
      // });
    },
    getRedisKey(tab, searchKey) {
      this.spinning = true;
      const callback = (callbackData) => {
        const keysData = [];
        callbackData.forEach((data) => {
          keysData.push({
            name: data,
            type: 'KEY'
          });
        });
        tab.keys = keysData || [];
        tab.filteredKeyList = deepClone(tab.keys) || [];
        if (!tab.pageData) {
          tab.pageData = {
            page: 1,
            size: 100
          };
        }
        tab.showKeyList = tab.filteredKeyList.slice(0, tab.pageData.size);
        tab.keyList = keysData || [];
        this.currentDbOrSchema = tab;
        this.currentDbOrSchema = { ...this.currentDbOrSchema };
      };
      this.topKeysWithLimit(tab, callback, searchKey);
    },
    expandCurrentDs() {
      this.dataSourceList.map((env) => {
        env.children.map((ds) => {
          if (ds.id === this.currentTab.instanceId) {
            env.expand = true;
            if (ds.children && ds.children.length > 0) {
              ds.expand = true;
              ds.children.map((db) => {
                if (db.title === this.currentTab.database) {
                  if (db.hasSchema) {
                    db.children.map((schema) => {
                      if (schema.database === this.currentTab.database && schema.title === this.currentTab.schema) {
                        schema.selected = true;
                        this.currentDbOrSchema = schema;
                        if (!schema.tableList) {
                          const callback = (data) => {
                            schema.tableList = data.TABLE;
                            schema.viewList = data.VIEW;
                            this.currentDbOrSchema = schema;
                            this.currentDbOrSchema = { ...this.currentDbOrSchema };
                          };
                          this.listDbTablesByExpand(schema, callback);
                        }
                      } else {
                        schema.selected = false;
                      }
                      return null;
                    });
                  } else {
                    db.selected = true;
                    this.currentDbOrSchema = db;
                    if (!db.tableList) {
                      const callback = (data) => {
                        db.tableList = data.TABLE;
                        db.viewList = data.VIEW;
                        this.currentDbOrSchema = db;
                        this.currentDbOrSchema = { ...this.currentDbOrSchema };
                      };
                      this.listDbTablesByExpand(db, callback);
                    }
                  }
                } else {
                  db.selected = false;
                }
                return null;
              });
            } else {
              const extendds = () => {
                ds.children = ds.childrenList.slice(0, this.showCount);
                ds.expand = true;
                ds.children.map((db) => {
                  if (db.hasSchema) {
                    if (db.children && db.children.length > 0) {
                      db.expand = true;
                      db.children.map((schema) => {
                        if (schema.title === this.currentTab.schema) {
                          schema.selected = true;
                          this.currentDbOrSchema = schema;
                          if (!schema.tableList) {
                            const callback = (data) => {
                              schema.tableList = data.TABLE;
                              schema.viewList = data.VIEW;
                              this.currentDbOrSchema = schema;
                              this.currentDbOrSchema = { ...this.currentDbOrSchema };
                            };
                            this.listDbTablesByExpand(schema, callback);
                          }
                        } else {
                          schema.selected = false;
                        }
                        return null;
                      });
                    } else {
                      const extends2 = () => {
                        db.children = db.childrenList.slice(0, this.showCount);
                        if (db.title === this.currentTab.database) {
                          db.expand = true;
                        }
                        db.children.map((schema) => {
                          if (schema.database === this.currentTab.database && schema.title === this.currentTab.schema) {
                            schema.selected = true;
                            this.currentDbOrSchema = schema;
                            if (!schema.tableList) {
                              const callback = (data) => {
                                schema.tableList = data.TABLE;
                                schema.viewList = data.VIEW;
                                this.currentDbOrSchema = schema;
                                this.currentDbOrSchema = { ...this.currentDbOrSchema };
                              };
                              this.listDbTablesByExpand(schema, callback);
                            }
                          } else {
                            schema.selected = false;
                          }
                          return null;
                        });
                      };
                      this.listDbSchemas(db, extends2);
                    }
                  } else {
                    if (db.title === this.currentTab.database) {
                      db.selected = true;
                      this.currentDbOrSchema = db;
                      if (!db.tableList) {
                        const callback = (data) => {
                          db.tableList = data.TABLE;
                          db.viewList = data.VIEW;
                          this.currentDbOrSchema = db;
                          this.currentDbOrSchema = { ...this.currentDbOrSchema };
                        };
                        this.listDbTablesByExpand(db, callback);
                      }
                    } else {
                      db.selected = false;
                    }
                  }
                  return null;
                });
              };
              this.listFirstLevel({
                id: ds.id,
                dsEnvId: env.id
              }, extendds);
            }
          } else {
            if (ds.children) {
              ds.children.map((db) => {
                db.selected = false;
                if (db.children) {
                  db.children.map((schema) => {
                    schema.selected = false;
                    return null;
                  });
                }
                return null;
              });
            }
          }
          return null;
        });
        return null;
      });
      this.dataSourceList.push('');
      this.dataSourceList.pop();
      this.curentMenu++;
      this.curentMenu++;
    },
    handleRefreshTables(type) {
      if (type === 'KEY') {
        this.getRedisKey(this.currentDbOrSchema);
      } else {
        this.getTableList(this.currentDbOrSchema);
      }
    },
    getTableList(ds) {
      if (ds.type === 'addDb') {
        this.dataSourceList.map((item) => {
          item.children.map((ds1) => {
            if (ds1.id === ds.instanceId) {
              if (!ds1.nextStart) {
                ds1.nextStart = this.showCount;
              }
              ds1.children = ds1.childrenList.slice(0, ds1.nextStart + this.showCount);
              ds1.nextStart += this.showCount;
              if (ds1.childrenList.length > ds1.nextStart) {
                ds1.children.push(ds1.addMore);
              }
            }
            return null;
          });
          return null;
        });
        return;
      }
      if (ds.type === 'db') {
        ds.database = ds.title;
      } else if (ds.type === 'schema') {
        ds.schema = ds.title;
      }
      if (!hasSchema(ds.dataSourceType) || ds.type !== 'db') {
        const callback = (data) => {
          ds.tables = data.TABLE || [];
          ds.views = data.VIEW || [];
          ds.filteredTableList = deepClone(ds.tables) || [];
          ds.filteredViewList = deepClone(ds.views) || [];
          ds.pageData = {
            page: 1,
            size: 100
          };
          ds.viewPageData = {
            page: 1,
            size: 100
          };
          ds.showTableList = ds.filteredTableList.slice(0, ds.pageData.size);
          ds.showViewList = ds.filteredViewList.slice(0, ds.pageData.size);
          this.currentDbOrSchema = ds;
          this.currentDbOrSchema = { ...this.currentDbOrSchema };
        };
        this.listDbTablesByExpand(ds, callback);
      } else {
        this.currentDbOrSchema = ds;
        this.currentDbOrSchema = { ...this.currentDbOrSchema };
      }
    },
    handleChangeCurrentDb() {
      this.getTableList(this.currentTab);
    },
    updateResultSpining(data) {
      this.resultSpining = data;
    }
  },
  destroyed() {
    document.removeEventListener('resize', this.calcHeight);
  },
  watch: {
    active() {
      // this.expandCurrentDs();
    }
  }
};
</script>
