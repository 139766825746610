<template>
  <div class="struct-view">
    <div class="struct-content-switch">
      <a-radio-group size="small" :value="tabItem">
        <a-radio-button value="basic" @click="handleChangeStructType">基本信息</a-radio-button>
        <a-radio-button value="column" @click="handleChangeStructType" :disabled="JSON.stringify(initEditorData)==='{}'">列信息</a-radio-button>
        <a-radio-button value="index" @click="handleChangeStructType" :disabled="JSON.stringify(initEditorData)==='{}'">索引信息</a-radio-button>
        <a-radio-button value="fk" @click="handleChangeStructType" :disabled="JSON.stringify(initEditorData)==='{}'">外键信息</a-radio-button>
        <a-radio-button value="create" @click="handleChangeStructType" v-if="isMySQL" :disabled="JSON.stringify(initEditorData)==='{}'">建表语句</a-radio-button>
      </a-radio-group>
    </div>
    <div class="struct-content" :style="`height:${viewHeight - 25}px`">
      <div v-if="tabItem === 'basic'" class="table-basic-info">
        <edit-basic :basic-data="basicData" ref="edit_table_basic"
                    :push-history="pushHistory" :schema-editor-apply="schemaEditorApply"/>
      </div>
      <div v-if="tabItem === 'column'" class="struct-column-container">
        <edit-column :struct-data="structData" ref="edit_table_struct"
                     :view-height="viewHeight" :push-history="pushHistory" :schema-editor-apply="schemaEditorApply"
                     :primary-key="primaryKey" :tab="tab" :isMySQL="isMySQL" :history="history" :deleteHistory="deleteHistory"
                     :initEditorData="initEditorData"/>
      </div>
      <div v-if="tabItem === 'index'" class="struct-column-container">
        <edit-index :indexData="indexData" :column-name-list="columnNameList"
                    ref="edit_table_index" :view-height="viewHeight"
                    :change-row-primary-key="changeRowPrimaryKey" :push-history="pushHistory"
                    :schema-editor-apply="schemaEditorApply" :isMySQL="isMySQL" :history="history"
                    :deleteHistory="deleteHistory" :initEditorData="initEditorData"/>
      </div>
      <div v-if="tabItem === 'fk'" class="struct-column-container">
        <edit-f-k :fk-data="fkData" ref="edit_table_fk" :view-height="viewHeight"/>
      </div>
      <div v-if="tabItem === 'create'" class="struct-create-container">
        <div class="content"
             style="border: 1px solid rgba(218, 218, 218, 1);height: 540px;overflow-y: scroll;padding: 10px;">
          <pre>{{ createSQL }}</pre>
        </div>
      </div>
    </div>
    <a-modal :visible="showSaveChangesModal" title="保存变更" @cancel="hideSaveChanges" v-if="showSaveChangesModal"
             :width="1240">
      <div class="content"
           style="border: 1px solid rgba(218, 218, 218, 1);height: 540px;overflow-y: scroll;padding: 10px;">
        <div v-for="(sql, index) in ddlSQLs" :key="index">{{ sql }}</div>
      </div>
      <div class="footer">
        <a-button type="primary" @click="execute" v-if="hasDDLAuth">直接执行</a-button>
        <a-button type="primary" @click="goTicketPage(ddlSQLs.join('\n'))" v-else>转到工单</a-button>
        <a-button @click="hideSaveChanges">取消</a-button>
      </div>
    </a-modal>
    <a-modal title="执行失败" :visible="showExecFailModal">
      <div class="content" style="background: #FFA71E;padding: 17px;color: #fff;">
        <div style="margin-bottom: 8px;font-weight: bold;">
          <span style="font-size: 14px;">执行失败</span>
        </div>
        <div>
          {{ errorMessage }}
        </div>
      </div>
      <div class="footer">
        <!--        <a-button type="primary" @click="goTicketPage">提交工单</a-button>-->
        <a-button @click="hideShowExecFailModal">关闭</a-button>
      </div>
    </a-modal>
  </div>
</template>
<script>
import deepClone from 'lodash.clonedeep';
import EditColumn from '@views/sql/components/struct/EditColumn.vue';
import { MYSQL_DATA_TYPE, PG_GP } from '@/consts';
import EditIndex from '@views/sql/components/struct/EditIndex.vue';
// import Vue from 'vue';
import EditBasic from '@views/sql/components/struct/EditBasic.vue';
import EditFK from '@views/sql/components/struct/EditFk.vue';
import { Message } from 'view-design';
import sqlMixin from '../../../../mixins/sqlMixin';

const EMPTY_DATA = {
  data: null,
  adds: [],
  deletes: []
};

export default {
  name: 'StructView',
  mixins: [sqlMixin],
  components: {
    EditFK,
    EditBasic,
    EditIndex,
    EditColumn
  },
  props: {
    tab: Object,
    refreshTables: Function,
    handleEditTabs: Function
  },
  data() {
    return {
      tabItem: 'basic',
      isMySQL: false,
      hasDDLAuth: false,
      errorMessage: {},
      executeData: {},
      showExecFailModal: false,
      showSaveChangesModal: false,
      editEditorData: {},
      initEditorData: {},
      history: [],
      NUM_TYPES: ['bigint', 'tinyint', 'varchar'],
      TYPES: ['bigint', 'tinyint', 'datetime', 'varchar'],
      columnNameList: [],
      structData: [],
      basicData: {},
      indexData: [],
      fkData: [],
      primaryKey: {},
      structDataEdited: false,
      basicDataEdited: false,
      indexDataEdited: false,
      fkDataEdited: false,
      editStructData: { ...EMPTY_DATA },
      editBasicData: { ...EMPTY_DATA },
      editIndexData: { ...EMPTY_DATA },
      editFkData: { ...EMPTY_DATA },
      createSQL: '',
      viewHeight: 0,
      ddlSQLs: ''
    };
  },
  mounted() {
    this.calcViewHeight();
    this.initData();
    window.addEventListener('resize', this.calcViewHeight, false);
  },
  methods: {
    handleCancel() {
      this.handleEditTabs(this.tab.key, 'remove');
    },
    hideShowExecFailModal() {
      this.showExecFailModal = false;
    },
    execute() {
      if (this.ddlSQLs.length) {
        this.onRun(this.ddlSQLs.join(''));
      }
      // this.onRun(this.ddlSQLs);
    },
    onRun(selectedSql = '') {
      if (!selectedSql) {
        selectedSql = `${this.sql} LIMIT ${this.currentPage * this.pageSize},${this.pageSize};`;
      }

      let data = {};
      if (PG_GP.includes(this.tab.dataSourceType)) {
        data = {
          type: 'WS_EXEC',
          object: {
            sessionId: this.tab.sessionId,
            queryString: selectedSql,
            queryTimeout: 10,
            dataSourceType: this.tab.dataSourceType,
            currentDb: this.tab.database,
            currentSchema: this.tab.catalog,
            dataSourceId: this.tab.instanceId
          }
        };
      } else {
        data = {
          type: 'WS_EXEC',
          object: {
            sessionId: this.tab.sessionId,
            queryString: selectedSql,
            queryTimeout: 10,
            dataSourceType: this.tab.dataSourceType,
            currentDb: this.tab.database,
            dataSourceId: this.tab.instanceId
          }
        };
      }

      this.tab.resultList = [];
      this.createWebSocket();
      if (!this.tab.autoCommit) {
        this.tab.readyToCommit = true;
      }
      this.ws.onclose = () => {
        console.log(`llws连接关闭!${new Date().toLocaleString()}`);
      };
      this.ws.onerror = () => {
        console.log('llws连接错误!');
        this.running = false;
      };
      this.ws.onopen = () => {
        console.log(`llws连接成功!${new Date().toLocaleString()}`);
        this.ws.send(JSON.stringify(data));
      };
      this.ws.onmessage = (event) => { // 如果获取到消息，心跳检测重置
        const queryData = JSON.parse(event.data);
        if (queryData.type === 'WS_RESULT') {
          if (queryData.object.success) {
            // this.executeData = queryData.object;
            // this.handleQuery();
            this.showExecFailModal = false;
            this.showSaveChangesModal = false;
            this.refreshTables(this.tab.currentDbOrSchema);
            this.history = [];
            this.initSchemaEditor();
            Message.success('修改成功');
          } else {
            this.showExecFailModal = true;
            this.errorMessage = queryData.object.message || queryData.object.object;
          }
          // this.tab.sessionId = queryData.object.sessionId;
        } else if (queryData.type === 'WS_ERROR') {
          this.showExecFailModal = true;
          this.showSaveChangesModal = false;
          this.errorMessage = queryData.object.message || queryData.object.object;
        } else if (queryData.type === 'WS_RULES') {
          this.errorMessage = `【安全规则】${queryData.object.ruleNames.join('；')}`;
        } else if (queryData.type === 'WS_DONE') {
          this.ws.close();
          this.running = false;
        }
      };
      // this.analysisSplit(selectedSql);
    },
    deleteHistory(index) {
      this.history.splice(index, 1);
    },
    createWebSocket() {
      try {
        if ('WebSocket' in window) {
          const protocol = document.location.protocol;
          let wsPre = 'ws';
          if (protocol === 'https:') {
            wsPre = 'wss';
          }
          this.ws = new WebSocket(`${wsPre}://${window.location.host}/clouddm/console/api/v1/query_socket`);
        }
      } catch (e) {
        console.log(e);
      }
    },
    hideSaveChanges() {
      this.showSaveChangesModal = false;
    },
    pushHistory(data) {
      console.log(data);
      const len = this.history.length;

      if (len) {
        const lastHistory = this.history[len - 1];

        if (lastHistory.type === 'COLUMN_CHANGE' && data.type === 'COLUMN_CHANGE' && lastHistory.args.COLUMNS[0].NAME === data.args.COLUMNS[0].NAME) {
          this.history.splice(len - 1, 1);
        }
      }

      this.history.push(data);
    },
    async handleSaveUpdate(switchTab, scripts) {
      switch (this.tabItem) {
        case 'column':
          this.$refs.edit_table_struct.switchTab(true);
          break;
        case 'index':
          this.$refs.edit_table_index.switchTab(true);
          break;
        default:
          break;
      }

      if (this.history.length) {
        await this.checkDDLAuth();
        await this.schemaEditorApply(this.history, true, switchTab, scripts);
      }
    },
    async schemaEditorApply(actions, showModal = false, switchTab = false, scripts = false) {
      if (!showModal) {
        this.history.push(...actions);
      }

      const {
        editorData,
        useDelimited,
        delimitedCaseSensitivity,
        plainCaseSensitivity
      } = this.initEditorData;
      const {
        dataSourceType,
        instanceId,
        database,
        selectedTable,
        catalog
      } = this.tab;
      let data = {};
      if (PG_GP.includes(dataSourceType)) {
        data = {
          catalog: database,
          dataSourceId: instanceId,
          schema: catalog,
          table: this.basicData.name || selectedTable
        };
      } else {
        data = {
          catalog: '',
          dataSourceId: instanceId,
          schema: database,
          table: selectedTable
        };
      }

      let lastTableRenameAction = {};
      let lastTableCommentAction = {};
      const tableRenameActions = [];
      const tableCommentActions = [];
      const generateActions = [];

      this.history.forEach((h) => {
        if (h.type === 'TABLE_RENAME') {
          tableRenameActions.push(h);
        } else if (h.type === 'TABLE_COMMENT') {
          tableCommentActions.push(h);
        } else {
          generateActions.push(h);
        }
      });

      if (tableRenameActions.length) {
        lastTableRenameAction = tableRenameActions[tableRenameActions.length - 1];
      }

      if (tableCommentActions.length) {
        lastTableCommentAction = tableCommentActions[tableCommentActions.length - 1];
      }

      if (lastTableRenameAction.type) {
        generateActions.push(lastTableRenameAction);
      }

      if (lastTableCommentAction.type) {
        generateActions.push(lastTableCommentAction);
      }
      const res = await this.$services.schemaEditorApply({
        data: {
          ...data,
          actions: generateActions,
          editorData,
          useDelimited,
          caseSensitivity: useDelimited ? delimitedCaseSensitivity : plainCaseSensitivity,
          targetDsIdentity: this.tab.dataSourceType
        }
      });

      if (res.success) {
        if (showModal) {
          this.ddlSQLs = res.data.scripts;
          if (!scripts) {
            this.initSchemaApplyData(res, true);
            this.showSaveChangesModal = true;
          }
        }
        if (switchTab) {
          this.initSchemaApplyData(res, true);
        }
      }
    },
    changeRowPrimaryKey(name, value) {
      if (!this.editStructData.data) {
        this.structDataEdited = true;
        this.editStructData.data = deepClone(this.structData);
      }
      this.editStructData.data.forEach((column) => {
        if (name.includes(column.name)) {
          column.primaryKey = value;
          if (value) {
            column.nullable = false;
          }
        }
      });

      this.editStructData = { ...this.editStructData };
    },
    handleRefresh(type) {
      if (type === 'basic') {
        this.getDbTable();
      } else if (type === 'index') {
        this.listTableIndex();
      } else if (type === 'fk') {
        this.listTableFk();
      } else if (type === 'create') {
        this.showCreateTable();
      } else if (type === 'column') {
        this.listTabColumns();
      }
    },
    initData() {
      // this.getDbTable();
      // this.listTableIndex();
      // this.listTableFk();
      const isMySQL = this.tab.dataSourceType === 'MySQL';
      this.isMySQL = isMySQL;
      if (isMySQL) {
        this.showCreateTable();
      }

      // this.listTabColumns();
      this.initSchemaEditor();
    },
    calcViewHeight() {
      this.viewHeight = window.innerHeight - 40 - 50 - 25 - 40;
    },
    initSchemaApplyData(res, edit = false) {
      if (res.success) {
        if (edit) {
          this.editEditorData = res.data;
        } else {
          this.initEditorData = res.data;
        }

        const { editorData } = res.data;
        const {
          columnList,
          attribute,
          comment,
          foreignKeys,
          indices,
          name,
          primaryKey,
          schema
        } = editorData;
        this.primaryKey = primaryKey;
        this.basicData = {
          ...attribute,
          comment,
          name,
          schema
        };
        this.structData = columnList;
        columnList.forEach((columnName) => {
          this.columnNameList.push(columnName.name);
        });
        this.indexData = indices;
        this.fkData = foreignKeys;
      }
    },
    async initSchemaEditor() {
      const {
        dataSourceType,
        instanceId,
        database,
        selectedTable,
        catalog
      } = this.tab;
      let data = {};
      if (PG_GP.includes(dataSourceType)) {
        data = {
          catalog: database,
          dataSourceId: instanceId,
          schema: catalog,
          table: this.basicData.name || selectedTable
        };
      } else {
        data = {
          catalog: '',
          dataSourceId: instanceId,
          schema: database,
          table: selectedTable
        };
      }
      const res = await this.$services.schemaEditorInit({
        data
      });
      this.initSchemaApplyData(res);
    },
    async getDbTable() {
      const data = {
        dataSourceId: this.tab.instanceId,
        parentData: '',
        schemaName: this.tab.database,
        tableName: this.tab.selectedTable,
        useVisibility: false
      };
      const res = await this.$services.getDbTable({ data });
      if (res.success) {
        this.basicData = res.data;
      }
    },
    async listTableIndex() {
      const data = {
        dataSourceId: this.tab.instanceId,
        parentData: '',
        schemaName: this.tab.database,
        tableName: this.tab.selectedTable
      };
      const res = await this.$services.listTableIndex({ data });
      if (res.success) {
        this.indexData = res.data;
      }
    },
    async listTableFk() {
      const data = {
        dataSourceId: this.tab.instanceId,
        parentData: '',
        schemaName: this.tab.database,
        tableName: this.tab.selectedTable
      };
      const res = await this.$services.listTableFk({ data });
      if (res.success) {
        this.fkData = res.data;
      }
    },
    async showCreateTable() {
      const data = {
        dataSourceId: this.tab.instanceId,
        parentData: '',
        schemaName: this.tab.database,
        objectName: this.tab.selectedTable,
        requestObjectType: 'Table'
      };
      const res = await this.$services.requestScript({ data });
      if (res.success) {
        this.createSQL = res.data;
      }
    },
    async listTabColumns() {
      const data = {
        dataSourceId: this.tab.instanceId,
        parentData: '',
        schemaName: this.tab.database,
        tableName: this.tab.selectedTable,
        useVisibility: false
      };
      const res = await this.$services.listTabColumns({ data });

      const matchReg = (column) => {
        const reg = /(.+?)\((.+?)\)/;
        return reg.exec(column);
      };

      if (res.success) {
        const columnNameList = [];
        res.data.forEach((item) => {
          columnNameList.push(item.name);
          const result = matchReg(item.columnType);
          if (result) {
            item.columnTypeBase = result[1];
            item.columnTypeNum = result[2];
          }
          item.unsigned = item.columnType.includes('unsigned');
          if (MYSQL_DATA_TYPE.ENUM.includes(item.dbType)) {
            item.enum = item.columnTypeNum && item.columnTypeNum.replaceAll('\'', '')
              .split(',');
          }
        });

        this.columnNameList = columnNameList;
        this.structData = res.data;
      }
    },
    async checkDDLAuth() {
      console.log(this.tab);
      const {
        dataSourceType, catalog, database, selectedTable, instanceId
      } = this.tab;
      const data = {
        dataSourceId: instanceId,
        dsAuthKind: 'DDL'
      };
      if (PG_GP.includes(dataSourceType)) {
        data.levelElements = [catalog, database, selectedTable];
      } else {
        data.levelElements = [database, selectedTable];
      }
      const res = await this.$services.schemaCheckDdl({
        data
      });

      if (res.success) {
        this.hasDDLAuth = res.data;
      }
    },
    async syncEditData(type) {
      switch (type) {
        case 'column':
          this.structDataEdited = true;
          const columnFlag = await this.$refs.edit_table_struct.switchTab();
          return columnFlag;
        case 'index':
          this.indexDataEdited = true;
          const indexFlag = await this.$refs.edit_table_index.switchTab();
          console.log('indexFlag', indexFlag);
          return indexFlag;
        case 'basic':
          this.basicDataEdited = true;
          this.$refs.edit_table_basic.switchTab();
          return true;
        case 'fk':
          this.fkDataEdited = true;
          return true;
        case 'create':
          return true;
        // this.editFkData = this.$refs.edit_table_fk.switchTab()
        default:
          break;
      }
    },
    async handleChangeStructType(e) {
      let type = this.tabItem;
      if (e.target) {
        type = e.target.value;
      }
      const preType = this.tabItem;

      const flag = await this.syncEditData(preType);
      if (flag) {
        // Vue.set(this.tab, 'structType', type);
        this.tabItem = type;
        // if (type === 'create') {
        //   await this.handleSaveUpdate(true, true);
        // }
      }
    }
  },
  // watch: {
  //   tab() {
  //     console.log('watch tab');
  //     this.getDbTable();
  //   }
  // },
  destroyed() {
    window.removeEventListener('resize', this.calcViewHeight);
  }
};
</script>

<style scoped lang="less">
.struct-view {
  .op {
    line-height: 50px;
    text-align: center;
    height: 50px;
    width: 100%;
    background-color: #F5F5F5;
  }
}
</style>
