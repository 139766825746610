<template>
  <div :class="`table-list-container ${closeTable?'table-close':'table-open'}`">
    <div v-if="closeTable" class="closed-table-list">
      <cc-iconfont @click.native="handleCloseTableList" :size="14" class="icon" color="#999999"
                   name="zhankai"></cc-iconfont>
    </div>
    <div v-if="!closeTable">
      <a-tabs default-active-key="TABLE" :animated="false">
        <a-tab-pane key="TABLE" tab="表" v-if="!noStruct(currentDbOrSchema.dataSourceType)">
          <div class="table-list-content">
            <div class="search-header">
              <a-input v-model="currentDbOrSchema.tableSearchKey" style="width: 152px;margin-right: 6px"
                       @change="handleSearchTable(currentTab,'TABLE')" size="small">
                <a-icon slot="prefix" type="search"/>
              </a-input>
              <cc-iconfont style="margin-right: 6px" @click.native="handleRefreshTables" :size="14" class="icon"
                           color="#999999"
                           name="refresh"></cc-iconfont>
<!--              <cc-iconfont @click.native="handleCloseTableList" :size="14" class="icon" color="#999999"-->
<!--                           name="shouqi"></cc-iconfont>-->
            </div>
            <div class="table-query-item-container">
              <a-spin :spinning="spinning" style="width: 100%;min-height: 200px">
                <div v-for="table in currentDbOrSchema.showTableList" :key="table.name">
                  <a-dropdown :trigger="['contextmenu']">
                    <div
                      :class="`table-query-item ${currentTable===table.name?'table-query-item-selected':''}`"
                      @click="handleSelectTable(table.name,currentTab)" @dblclick="handleDblClickTable(table.name)">
                      <cc-iconfont style="margin-right: 4px" :size="14" class="icon" color="#555555"
                                   name="biao"></cc-iconfont>
                      {{ table.name }}
                    </div>
                    <a-menu slot="overlay">
                      <a-menu-item key="showStruct">
                        <div @click="handleShowStruct(currentDbOrSchema,table)">查看表结构</div>
                      </a-menu-item>
                      <a-menu-item key="createTicket">
                        <div @click="handleGoTicket(currentDbOrSchema)">提交工单</div>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </div>
              </a-spin>
              <p class="no-data-tip"
                 v-if="!currentDbOrSchema.tableList||currentDbOrSchema.tableList.length === 0">
                暂无表</p>
            </div>
            <TablePagination v-if="currentDbOrSchema&&currentDbOrSchema.pageData"
                             :filteredTableList="currentDbOrSchema.filteredTableList"
                             :pageDat="currentDbOrSchema.pageData"
                             :handleChangeTablePage="handleChangeTablePage" :tab="currentDbOrSchema"
                             :handleTablePageSize="handleTablePageSize"></TablePagination>
          </div>
        </a-tab-pane>
        <a-tab-pane key="VIEW" tab="视图" v-if="!noStruct(currentDbOrSchema.dataSourceType)">
          <div class="table-list-content">
            <div class="search-header">
              <a-input v-model="currentDbOrSchema.tableSearchKey" style="width: 152px;margin-right: 6px"
                       @change="handleSearchTable(currentDbOrSchema,'VIEW')" size="small">
                <a-icon slot="prefix" type="search"/>
              </a-input>
              <cc-iconfont style="margin-right: 6px" @click.native="handleRefreshTables" :size="14" class="icon"
                           color="#999999"
                           name="refresh"></cc-iconfont>
<!--              <cc-iconfont @click.native="handleTabDb(currentDbOrSchema)" :size="14" class="icon" color="#999999"-->
<!--                           name="shouqi"></cc-iconfont>-->
            </div>
            <div class="table-query-item-container">
              <a-spin :spinning="spinning" style="width: 100%;min-height: 200px">
                <div v-for="table in currentDbOrSchema.showViewList" @dblclick="handleDblClickTable(table.name)"
                     @click="handleSelectTable(table.name,currentDbOrSchema)" :key="table.name">
                  <a-dropdown :trigger="['contextmenu']">
                    <div
                      :class="`table-query-item ${currentTable===table.name?'table-query-item-selected':''}`"
                      @click="handleSelectTable(table.name,currentDbOrSchema)">
                      <cc-iconfont style="margin-right: 4px" :size="14" class="icon" color="#555555"
                                   name="shitu1"></cc-iconfont>
                      {{ table.name }}
                    </div>
                    <a-menu slot="overlay">
                      <a-menu-item key="showStruct">
                        <div @click="handleShowStruct(currentDbOrSchema,table)">查看表结构</div>
                      </a-menu-item>
                      <a-menu-item key="createTicket">
                        <div @click="handleGoTicket(currentDbOrSchema)">提交工单</div>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </div>
              </a-spin>
              <p class="no-data-tip"
                 v-if="!currentDbOrSchema.viewList||currentDbOrSchema.viewList.length === 0">
                暂无视图</p>
            </div>
            <TablePagination v-if="currentDbOrSchema&&currentDbOrSchema.viewPageData"
                             :filteredTableList="currentDbOrSchema.filteredViewList"
                             :pageDat="currentDbOrSchema.viewPageData"
                             :handleChangeTablePage="handleChangeViewPage" :tab="currentDbOrSchema"
                             :handleTablePageSize="handleViewPageSize"></TablePagination>
          </div>
        </a-tab-pane>
        <a-tab-pane key="KEY" tab="键" v-if="noStruct(currentDbOrSchema.dataSourceType)">
          <div class="table-list-content">
            <div class="search-header">
              <a-input v-model="currentDbOrSchema.tableSearchKey" style="width: 152px;margin-right: 6px"
                       @keyup.enter="handleSearchTable(currentTab,'KEY')" size="small" placeholder="按下回车键搜索">
                <a-icon slot="prefix" type="search"/>
              </a-input>
              <cc-iconfont style="margin-right: 6px" @click.native="handleRefreshTables('KEY')" :size="14" class="icon"
                           color="#999999"
                           name="refresh"></cc-iconfont>
<!--              <cc-iconfont @click.native="handleCloseTableList" :size="14" class="icon" color="#999999"-->
<!--                           name="shouqi"></cc-iconfont>-->
            </div>
            <div class="table-query-item-container">
              <a-spin :spinning="spinning" style="width: 100%;min-height: 200px">
                <div v-for="table in currentDbOrSchema.showKeyList" :key="table.name">
                  <a-dropdown :trigger="['contextmenu']">
                    <div
                      :class="`table-query-item ${currentTable===table.name?'table-query-item-selected':''}`"
                      @click="handleSelectTable(table.name,currentTab)" @dblclick="handleDblClickTable(table.name)">
                      <cc-iconfont style="margin-right: 4px" :size="14" class="icon" color="#555555"
                                   name="biao"></cc-iconfont>
                      {{ table.name }}
                    </div>
                    <a-menu slot="overlay">
                      <a-menu-item key="createTicket">
                        <div @click="handleGoTicket(currentDbOrSchema)">提交工单</div>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </div>
              </a-spin>
              <p class="no-data-tip"
                 v-if="!currentDbOrSchema.keyList||currentDbOrSchema.keyList.length === 0">
                暂无键</p>
            </div>
            <TablePagination v-if="currentDbOrSchema&&currentDbOrSchema.pageData"
                             :filteredTableList="currentDbOrSchema.filteredKeyList"
                             :pageDat="currentDbOrSchema.pageData"
                             :handleChangeTablePage="handleChangeTablePage" :tab="currentDbOrSchema"
                             :handleTablePageSize="handleTablePageSize"></TablePagination>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import TablePagination from '@views/sql/components/TablePagination.vue';
import { noStruct } from '@utils';

export default {
  components: { TablePagination },
  props: {
    closeTable: Boolean,
    handleCloseTableList: Function,
    currentDbOrSchema: Object,
    handleSearchTable: Function,
    handleRefreshTables: Function,
    currentTab: Object,
    handleSelectTable: Function,
    handleShowStruct: Function,
    handleGoTicket: Function,
    handleChangeTablePage: Function,
    handleTabDb: Function,
    handleTablePageSize: Function,
    handleChangeViewPage: Function,
    handleViewPageSize: Function,
    spinning: Boolean,
    handleNewDbQuery: Function,
    currentTable: String
  },
  data() {
    return {
      noStruct
    };
  },
  methods: {
    handleDblClickTable(table) {
      this.handleNewDbQuery(this.currentDbOrSchema, table);
    }
  }
};
</script>
