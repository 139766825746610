<template>
  <div>
    <div :style="`height:${pageHeight}px`" class="result-container">
      <a-spin :spinning="resultSpining">
        <a-tabs v-if="resultContainer==='result'" :animated="false"
                name="result" @change="handleTabChange" v-model="selectedResultTab.index">
          <a-tab-pane
            key="message"
            name="message"
            tab="执行信息">
            <div :id="`result-info-${tab.key}`" :style="`height:${pageHeight}px`" class="result-info-container">
              <div v-for="(info,index) in tab.executeInfo" :key="index" class="result-info">
                <p>
                  <span class="result-info-db">{{ info.database }}></span>
                <pre class="result-info-sql">{{ info.queryBody }}</pre>
                </p>
                <p>
                  <span>[{{ info.time }}]</span>
                  <span :class="`result-info-message ${!info.success?'result-info-error':''}`">{{ info.message }}</span>
                </p>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane
            key="async"
            name="async"
            tab="异步执行管理列表" v-if="tab.asyncListTab">
            <div style="padding: 10px;">
              <AsyncJobList :biz-id="tab.sessionId" biz-type="QUERY_CONSOLE" :set-async-job-detail="setAsyncJobDetail" v-if="asyncJobDetailId === -1"/>
              <AsyncJobDetail :export-job-id="asyncJobDetailId" v-else :set-async-job-detail="setAsyncJobDetail" biz-type="QUERY_CONSOLE" type="console"/>
            </div>
          </a-tab-pane>
          <a-tab-pane
            v-for="(res,index) in resultList"
            :key="index"
            :closable="false">
          <span slot="tab">
            <cc-iconfont v-if="!res.success" style="margin-right:4px" name="error" color="#FF1815" :size="12"/>
            结果{{ index + 1 }}
          </span>
          </a-tab-pane>
        </a-tabs>
        <div v-if="!['message', 'async'].includes(selectedResultTab.index)">
          <div :style="`height:${pageHeight}px`" class="result-table-container">
            <div v-if="selectedResultTab.columnList.length===1" class="result-info-container">
              <div class="result-info">
                <p class="result-info-db" v-if="selectedResultTab.res.success">SUCCESS</p>
                <p class="result-info-db result-info-db-failed" v-if="!selectedResultTab.res.success">FAILED</p>
                <pre class="result-info-sql" style="display:block">{{ selectedResultTab.res.queryBody }}</pre>
                <p v-if="selectedResultTab.res.success">影响行数：{{selectedResultTab.res.updateCount}}</p>
                <p>
                  <span :class="`result-info-message ${!selectedResultTab.res.success?'result-info-error':''}`">{{ selectedResultTab.res.message }}</span>
                </p>
              </div>
            </div>
            <div v-else class="result-content">
              <div class="result-table">
                <!--              <div class="search">-->
                <!--                <a-input v-model="res.researchText" size="small" style="width: 300px;"/>-->
                <!--              </div>-->
                <!--              <vxe-input v-model="res.searchText" @keyup="handleSearchResult(res)"/>-->
                <vxe-grid
                  :ref="`result_table_${selectedResultTab.index}`"
                  v-if="selectedResultTab.columnList.length>0"
                  :data="pageData[selectedResultTab.index]?pageData[selectedResultTab.index].showData: []"
                  :height="pageHeight - 70"
                  stripe
                  resizable
                  border
                  highlight-hover-row
                  keep-source
                  show-overflow
                  size="small"
                  :export-config="exportConfig"
                  :columns="selectedResultTab.columnList"
                  @cell-click="handleCellClick"
                  :menu-config="tableMenu"
                  @menu-click="contextMenuClickEvent"
                  @cell-menu="handleRightClickCell"
                >
                  <!--            <vxe-table-column v-for="column in getColumnsList(res)" :key="column.field" :edit-render="column.editRender"-->
                  <!--                              :field="column.field" :title="column.title" :width="column.width"/>-->
                  <template #toolbar_buttons>
                    <vxe-button @click="exportData">导出</vxe-button>
                  </template>
                  <template #header_title="{column}">
                                      <cc-iconfont :size="12" :name="getIconByType(column.type)"/>
                    {{ column.title }}
                  </template>
                </vxe-grid>
              </div>
            </div>
          </div>
          <div class="tip-footer">
            <Page :current="selectedResultTab.res.page" :page-size="50"
                  :total="selectedResultTab.res.total"
                  placement="top" show-total
                  size="small" @on-change="changePage(selectedResultTab.index,$event)"
            ></Page>
            <p>执行SQL：
              <a-tooltip placement="top">
                <template slot="title">
                  <span>{{ selectedResultTab.res.queryBody }}</span>
                </template>
                <span>{{ selectedResultTab.res.queryBody }}</span>
              </a-tooltip>
            </p>
            <span>
            显示数据的{{ selectedResultTab.res.resultSet ? selectedResultTab.res.resultSet.length : 0 }}
                条记录(耗时：{{ selectedResultTab.res.executeTimeMs+selectedResultTab.res.fetchTimeMs }}ms)
          </span>
          </div>
        </div>
      </a-spin>
      <insert-sql-modal :visible="showInsertSqlModal" :sqls="sqls" :handle-close-modal="hideShowInsertSqlModal"/>
    </div>
  </div>
</template>
<script>
import { MYSQL_DATA_TYPE } from '@/consts';
import InsertSqlModal from '@views/sql/components/modal/InsertSqlModal.vue';
import { Modal } from 'ant-design-vue';
import {
  mysqlInsert, pgInsert, mysqlTypeGroup, pgTypeGroup
} from '@views/sql/components/typeGroup';
import { chunk } from 'xe-utils';
import AsyncJobList from '../../system/AsyncJobList/AsyncJobList.vue';
import AsyncJobDetail from '../../system/AsyncJobList/AsyncJobDetail.vue';

export default {
  name: 'Result',
  props: {
    resultList: Array,
    tab: Object,
    resultSpining: Boolean
  },
  components: {
    AsyncJobDetail,
    AsyncJobList,
    InsertSqlModal
    // CommandViewer
  },
  data() {
    return {
      columnsList: [],
      searchText: '',
      sqls: [],
      showInsertSqlModal: false,
      rowIndex: 0,
      tableMenu: {
        body: {
          options: [
            [
              {
                code: 'exportRowInsert',
                name: '导出当前行insert'
              }
              // {
              //   code: 'exportCellInsert',
              //   name: '导出单元格insert'
              // }
            ]
          ]
        }
      },
      toolbarConfig: {
        custom: true,
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      exportConfig: {},
      selectedResultTab: {
        index: 'message',
        ref: null,
        res: {},
        columnList: []
      },
      resultContainer: 'result',
      selectedDB: 'db1',
      pageData: [],
      page: 1,
      resultTables: [],
      pageSize: 50,
      pageHeight: 0,
      asyncJobDetailId: -1
    };
  },
  mounted() {
    this.pageHeight = window.innerHeight - 430;
    const ele = document.getElementById(`result-info-${this.tab.key}`);

    ele.scrollTop = ele.scrollHeight;
  },
  methods: {
    getColumnsList(res) {
      const list = [{
        type: 'seq',
        width: 50,
        title: '序号',
        fixed: 'left',
        className: 'seq-content',
        headerClassName: 'seq-header'
      }];
        // 这边根据列和值来算width，值取一批的最长值，另需要设置最小宽度
      if (res.columnList) {
        res.columnList.map((item, index) => {
          const minWidth = 100; const
            maxWidth = 400;
          let width = 0;
          if (res.resultSet && res.resultSet[0]) {
            const value = res.resultSet[0][index].value;
            if (value && value.length > item.length) {
              width = value.length * 5 + 80;
            }
          }
          if (width === 0) {
            width = item.length * 5 + 80;
          }

          if (width < minWidth) {
            width = minWidth;
          }

          if (width > maxWidth) {
            width = maxWidth;
          }

          let cellRenderName = 'input';
          if (MYSQL_DATA_TYPE.TIME.includes(item)) {
            cellRenderName = 'time';
          }
          list.push({
            field: item,
            title: item,
            width,
            type: res.columnType[index],
            // type: res.columnType[index],
            cellRender: { name: `vxe-${cellRenderName}-tpl` },
            slots: {
              header: 'header_title'
            }
          });
          return null;
        });
      }
      return list;
    },
    handleTabChange(active) {
      this.selectedResultTab = {
        index: active,
        columnList: this.columnsList[active],
        res: this.pageData[active]
      };
    },
    setAsyncJobDetail(asyncJobDetail) {
      this.asyncJobDetailId = asyncJobDetail;
    },
    hideShowInsertSqlModal() {
      this.showInsertSqlModal = false;
    },
    handleRightClickCell(e) {
      this.handleCellClick(e);
    },
    contextMenuClickEvent(e) {
      const {
        menu,
        row,
        column,
        rowIndex
      } = e;
      this.handleCellClick(e);
      switch (menu.code) {
        case 'exportCell':
          if (row && column) {
            this.$refs[`result_table_${this.selectedResultTab.index}`][0].exportData({
              data: [this.selectedRow]
            });
          }
          break;
        case 'exportRow':
          if (row && column) {
            this.$refs[`result_table_${this.selectedResultTab.index}`][0].exportData({
              data: [this.selectedRow]
            });
          }
          break;
        case 'exportRowInsert':
          this.handleExport({
            key: 'currentInsert',
            rowIndex
          });
          break;
        default:
          break;
      }
    },
    async handleCellClick(e) {
      this.rowIndex = e.rowIndex;
      if (this.selectedCell) {
        this.selectedCell.style.backgroundColor = '';
      }
      if (this.selectedRowDom) {
        this.selectedRowDom.style.backgroundColor = '';
        this.selectedRowDom.children[0].style.backgroundColor = '';
        this.selectedRow = null;
        this.selectedRowDom = null;
      }
      e.cell.style.backgroundColor = '#C3DEFF';
      e.cell.parentNode.style.backgroundColor = e.$columnIndex === 0 ? '#C3DEFF' : '#E6F1FF';
      if (e.$columnIndex) {
        e.cell.parentNode.children[0].style.backgroundColor = '#E6F1FF';
      }

      this.selectedRowDom = e.cell.parentNode;
      this.selectedRow = e.row;
      this.selectedCell = e.cell;
    },
    async handleExport({
      key,
      rowIndex
    }) {
      const {
        resultSet,
        resource,
        columnList,
        columnType
      } = this.resultList[this.selectedResultTab.index];
      const sqls = [];
      const tableName = resource || 'my_table';
      let showInsertSqlModal = false;

      const generateRowInsert = (row) => {
        console.log(row);
        let keyStr = '';
        if (this.tab.dataSourceType === 'MySQL') {
          keyStr = `\`${columnList.join('`, `')}\``;
        } else {
          keyStr = `"${columnList.join('", "')}"`;
        }
        let valueStr = '';
        columnList.forEach((key1, index) => {
          const value = row[index].value;
          let insertType;
          if (this.tab.dataSourceType === 'MySQL') {
            insertType = mysqlInsert;
          } else {
            insertType = pgInsert;
          }
          if (index !== columnList.length - 1) {
            if (value === null) {
              valueStr += 'null, ';
            } else if (insertType.needQuote.indexOf(columnType[index]) > -1) {
              valueStr += `'${value}', `;
            } else if (insertType.noNeedQuote.indexOf(columnType[index]) > -1) {
              valueStr += `${value}, `;
            } else {
              valueStr += `'${value}', `;
            }
          } else {
            if (value === null) {
              valueStr += 'null';
            } else if (insertType.needQuote.indexOf(columnType[index]) > -1) {
              valueStr += `'${value}'`;
            } else if (insertType.noNeedQuote.indexOf(columnType[index]) > -1) {
              valueStr += `${value} `;
            } else {
              valueStr += `'${value}' `;
            }
          }
        });
        return `INSERT INTO ${tableName} (${keyStr}) VALUES (${valueStr})`;
      };
      switch (key) {
        case 'all':
          const { instance, database, currentTable } = this.tab;
          const list = [];
          const columns = this.resultList[this.selectedResultTab.index].columnList;
          if (this.pageData[this.selectedResultTab.index]) {
            this.pageData[this.selectedResultTab.index].data.map((item) => {
              const currentRow = {};
              for (let i = 0; i < columns.length; i++) {
                currentRow[columns[i]] = item[i];
                currentRow[`render_${columns[i]}`] = item[i];
              }
              list.push(currentRow);
              return null;
            });
          }
          this.$refs[`result_table_${this.selectedResultTab.index}`][0].exportData({
            filename: `${instance}/${database}/${currentTable}`,
            type: 'csv',
            data: list
          });
          break;
        case 'currentInsert':
          if (this.selectedRow) {
            showInsertSqlModal = true;
            let selectedRow = [];
            if (rowIndex) {
              selectedRow = resultSet[rowIndex];
            } else {
              selectedRow = resultSet[this.rowIndex];
            }
            sqls.push(generateRowInsert(selectedRow));
          } else {
            Modal.warning({
              content: '请至少选择一条数据！'
            });
          }

          break;
        case 'allInsert':
          showInsertSqlModal = true;
          resultSet.forEach((row) => {
            sqls.push(generateRowInsert(row));
          });
          break;
        default:
          break;
      }
      this.sqls = sqls;
      this.showInsertSqlModal = showInsertSqlModal;
    },
    exportData() {
      const { instance, database, currentTable } = this.currentTab;
      this.$refs[`result_table_${this.selectedResultTab.index}`][0].openExport({
        filename: `${instance}-${database}-${currentTable}`,
        types: ['csv']
      });
    },
    changePage(index, page) {
      this.pageData[index].page = page;
      this.pageData[index].showData = this.pageData[index].dataArr[page - 1];
    },
    changePageSize() {

    },
    getIconByType(type) {
      let iconName = '';
      let dbGroup;
      if (this.tab.dataSourceType === 'MySQL') {
        dbGroup = mysqlTypeGroup;
      } else {
        dbGroup = pgTypeGroup;
      }
      if (dbGroup.text.indexOf(type) > -1) {
        iconName = 'wenben';
      } else if (dbGroup.number.indexOf(type) > -1) {
        iconName = 'shuzi';
      } else if (dbGroup.date.indexOf(type) > -1) {
        iconName = 'riqi';
      } else if (dbGroup.binary.indexOf(type) > -1) {
        iconName = 'erjinzhi';
      } else {
        iconName = 'qita';
      }
      console.log(iconName);
      return iconName;
    }
  },
  watch: {
    resultList(val) {
      console.log('resultList');
      if (val) {
        this.pageData = [];
        const columnsList = [];
        val.forEach((v) => {
          const { resultSet, columnList } = v;
          const list1 = this.getColumnsList(v);
          columnsList.push(list1);
          const list = [];
          if (resultSet) {
            resultSet.forEach((item) => {
              const currentRow = {};
              for (let i = 0; i < columnList.length; i++) {
                currentRow[columnList[i]] = item[i].value;
                currentRow[`render_${columnList[i]}`] = item[i];
              }
              list.push(currentRow);
            });
          }

          const dataArr = chunk(list, 50);
          this.pageData.push({
            ...v,
            page: 1,
            size: 50,
            total: resultSet ? resultSet.length : 0,
            data: resultSet,
            dataArr,
            showData: resultSet ? dataArr[0] : []
          });
        });
        if (val.length > 0) {
          this.selectedResultTab = {
            // key: `result${val.length - 1}`,
            index: val.length - 1,
            res: this.pageData[val.length - 1],
            columnList: columnsList[val.length - 1]
          };
        }
        this.columnsList = columnsList;
      }
    },
    'tab.asyncListTab': {
      handler(newVal) {
        console.log(newVal);
        if (newVal) {
          this.selectedResultTab = {
            index: 'async'
          };
        }
      },
      deep: true
    },
    'tab.running': {
      handler(newVal) {
        this.spining = newVal;
      },
      deep: true
    }
  }
};
</script>
<style lang="less">
.tip-footer {
  position: absolute;
  bottom: 40px;
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #DDDDDD;
  color: #333333;
  background: #FFFFFF;
}

.page-changer {
  //position: absolute;
  //right: 10px;
  //top: 0;
}

.result-container .ant-tabs-bar {
  margin: 0;
  z-index: 9;
}

.result-container {
  width: 100%-260px;
  overflow: hidden;

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
  }

  .ant-table-body {
    overflow: auto;
  }

  .ant-table .ant-table-thead {
    background: #F1F1F1;
  }

  .ant-tabs {
    height: 100%;

    .ant-tabs-nav {
      .ant-tabs-tab:hover {
        color: #0BB9F8;
      }

      .ant-tabs-tab-active {
        color: #0BB9F8;
      }

      .ant-tabs-ink-bar {
        background: #0BB9F8;
      }
    }
  }

  .ant-tabs-nav-scroll {
    background: #ffffff !important;
  }

  .ant-tabs-content {
    border-top: 1px solid #C7C7C7;
  }

  .ivu-page-next, .ivu-page-prev {
    background: none;
  }

  .ivu-page-item {
    background: none;
  }

  .ivu-table-wrapper-with-border {
    border: none;
  }

  .result-table-operation {
    height: 30px;
    width: 100%;
  }

  .result-table-container {
    width: 100%;
    //padding-bottom: 80px;
    overflow: auto;

    .result-content {
      display: flex;

      .result-table {
        width: calc(~"100%");

        .search {
          height: 40px;
          line-height: 40px;
          width: 100%;
          background: #F3F3F3;
          padding-left: 10px;
        }
      }

      .result-op {
        padding-top: 11px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #f0f0f0;
        width: 30px;
        border-left: 1px solid #C7C7C7;
      }
    }

  }

  .ant-table-body {
    .null-value {
      color: #999;
      font-style: italic;
    }

    .ant-table-tbody > tr > td {
      border-right: 1px solid #e8e8e8;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      padding: 4px 8px !important;
    }
  }

  .ant-table-thead > tr > th {
    border-right: 1px solid #e8e8e8;
  }

  .ant-table-row-cel {
    height: 27px !important;

    .ant-table-cell {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
  }

  .ivu-table-small .ivu-table-header thead tr th {
    height: 27px;
    line-height: 27px;
  }

  .result-info-container {
    padding: 8px 8px 80px 8px;
    overflow: auto;

    .result-info {
      margin-bottom: 20px;
      p {
        line-height: 20px;
      }

      .result-info-db {
        color: #00981C;
        margin-right: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
        display: inline-block;
        vertical-align: top;
      }

      .result-info-db-failed {
        color: #FF1815;
      }

      .result-info-sql {
        display: inline-block;
        margin-bottom: 10px;
        color: #333333;
      }

      .result-info-message {
        color: #555555;
        margin-top: 10px;
        margin-left: 6px;
      }

      .result-info-error {
        color: @error-color;
      }
    }
  }
  .vxe-table .vxe-table--header-wrapper {
    color: #333333;
  }
  .vxe-table--render-default {
    color: #333333;
  }
  .vxe-header--column {
    font-weight: 500;
  }
  .vxe-body--column {
    vertical-align: middle;
  }
  .vxe-input-tpl {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.seq-content,
.seq-header {
  background: #F5F5F5;
}
</style>
