<template>
  <div class="table-list-pagination">
    <a-select v-model="tab.pageData.size" @change="(value)=>handleTablePageSize(tab,value)" class="select-size"
              size="small">
      <a-select-option :value="50">
        50
      </a-select-option>
      <a-select-option :value="100">
        100
      </a-select-option>
      <a-select-option :value="200">
        200
      </a-select-option>
    </a-select>
    <span class="select-total">共{{ tableList && tableList.length }}张表</span>
    <span class="select-next">
      <Icon v-if="tab.pageData.page>1" class="page-pre" @click="handleChangeTablePage('pre',tab)"
            type="ios-arrow-back"/>
      <Icon v-if="tab.pageData.page<=1" class="page-pre page-not-allowed" type="ios-arrow-back"/>
      <span>{{ tab.pageData.page }}</span>
      <Icon v-if="tableList&&tableList.length>tab.pageData.page*tab.pageData.size"
            class="page-next"
            @click="handleChangeTablePage('next',tab)" type="ios-arrow-forward"/>
      <Icon v-if="tableList&&tableList.length<=tab.pageData.page*tab.pageData.size"
            class="page-next page-not-allowed"
            type="ios-arrow-forward"/>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    filteredTableList: Array,
    handleChangeTablePage: Function,
    handleTablePageSize: Function,
    tab: Object
  },
  data() {
    return {
      tableList: []
    };
  },
  watch: {
    filteredTableList: {
      deep: true,
      handler(val) {
        this.tableList = val;
      },
      immediate: true
    }
  }
};
</script>
<style lang="less">
.table-list-pagination {
  .select-size {
    width: 62px;
    float: left;
  }

  .select-total {
    line-height: 24px;
    vertical-align: middle;
  }

  .select-next {
    float: right;
    line-height: 24px;
    vertical-align: middle;

    .page-pre {
      font-size: 14px;
      margin-right: 8px;
      cursor: pointer;
    }

    .page-next {
      font-size: 14px;
      margin-left: 8px;
      cursor: pointer;
    }

    .page-not-allowed {
      cursor: not-allowed;
      color: #CCCCCC;
    }
  }
}
</style>
