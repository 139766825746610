var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"edit-column"},[_c('div',{staticClass:"struct-table-content"},[_c('vxe-table',{ref:"columnTable",attrs:{"data":_vm.editStructData,"height":_vm.viewHeight - _vm.advancedHeight - 65,"border":"","highlight-current-column":"","highlight-current-row":"","highlight-hover-column":"","highlight-hover-row":"","keep-source":"","show-overflow":"","show-status":"","size":"mini"},on:{"cell-click":_vm.handleCellClick,"edit-closed":_vm.handleEditBlur,"edit-actived":_vm.handleEditActive}},[_c('vxe-table-column',{attrs:{"edit-render":{},"field":"name","title":"名称"},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
var rowIndex = ref.rowIndex;
return [_c('vxe-input',{on:{"blur":function($event){return _vm.updateColumn(row, rowIndex)}},model:{value:(row.name),callback:function ($$v) {_vm.$set(row, "name", $$v)},expression:"row.name"}})]}}])}),_c('vxe-table-column',{attrs:{"edit-render":{},"field":"dbType","title":"类型"},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-pulldown',{ref:"typeDown",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('vxe-input',{attrs:{"placeholder":row.dbType},on:{"focus":_vm.focusEvent,"keyup":_vm.keyupEvent},model:{value:(row.dbType),callback:function ($$v) {_vm.$set(row, "dbType", $$v)},expression:"row.dbType"}})]},proxy:true},{key:"dropdown",fn:function(){return [(_vm.SEARCH_ALL_DATA_TYPE.length)?_c('div',{staticClass:"type-down"},_vm._l((_vm.SEARCH_ALL_DATA_TYPE),function(item){return _c('div',{key:item,staticClass:"list-item",on:{"click":function($event){return _vm.selectEvent(row, item)}}},[_c('i',{staticClass:"fa fa-user-o"}),_c('span',{staticStyle:{"width":"100px"}},[_vm._v(_vm._s(item))])])}),0):_vm._e()]},proxy:true}],null,true)})]}}])}),_c('vxe-table-column',{attrs:{"edit-render":{},"title":"长度"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.ALL_DATA_TYPE_MAP[row.dbType] === 'datetime')?_c('div',[_vm._v(_vm._s(row.datetimePrecision))]):(_vm.ALL_DATA_TYPE_MAP[row.dbType] === 'text')?_c('div',[_vm._v(_vm._s(row.length))]):(_vm.ALL_DATA_TYPE_MAP[row.dbType] === 'decimal' || _vm.ALL_DATA_TYPE_MAP[row.dbType] === 'number')?_c('div',[_vm._v(_vm._s(row.numericPrecision))]):_vm._e()]}},{key:"edit",fn:function(ref){
var row = ref.row;
var rowIndex = ref.rowIndex;
return [(_vm.ALL_DATA_TYPE_MAP[row.dbType] === 'datetime')?_c('vxe-input',{attrs:{"type":"number"},on:{"blur":function($event){return _vm.updateColumn(row, rowIndex)}},model:{value:(row.datetimePrecision),callback:function ($$v) {_vm.$set(row, "datetimePrecision", $$v)},expression:"row.datetimePrecision"}}):(_vm.ALL_DATA_TYPE_MAP[row.dbType] === 'text')?_c('vxe-input',{attrs:{"type":"number"},on:{"blur":function($event){return _vm.updateColumn(row, rowIndex)}},model:{value:(row.length),callback:function ($$v) {_vm.$set(row, "length", $$v)},expression:"row.length"}}):(_vm.ALL_DATA_TYPE_MAP[row.dbType] === 'decimal' || _vm.ALL_DATA_TYPE_MAP[row.dbType] === 'number')?_c('vxe-input',{attrs:{"type":"number"},model:{value:(row.numericPrecision),callback:function ($$v) {_vm.$set(row, "numericPrecision", _vm._n($$v))},expression:"row.numericPrecision"}}):_vm._e()]}}])}),_c('vxe-table-column',{attrs:{"title":"NULL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('vxe-checkbox',{attrs:{"disabled":"","close-label":"否","open-label":"是"},on:{"change":function($event){return _vm.handleBlur('nullable', row, $event)}},model:{value:(row.nullable),callback:function ($$v) {_vm.$set(row, "nullable", $$v)},expression:"row.nullable"}})]}}])}),_c('vxe-table-column',{attrs:{"title":"主键"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('vxe-checkbox',{attrs:{"value":_vm.primaryKey && _vm.primaryKey.columnList && _vm.primaryKey.columnList.includes(row.name),"disabled":""}})]}}])}),_c('vxe-table-column',{attrs:{"edit-render":{name: 'input', attrs: {type: 'text'}},"field":"comment","title":"备注"}})],1)],1),_c('a-modal',{attrs:{"border":"","title":"添加包含的值列表"},model:{value:(_vm.showAddEnum),callback:function ($$v) {_vm.showAddEnum=$$v},expression:"showAddEnum"}},[(_vm.showAddEnum)?_c('div',[_c('vxe-table',{ref:"enumTable",attrs:{"data":_vm.selectedColumn.enumArr,"edit-config":{trigger: 'click', mode: 'cell', showStatus: true},"height":"300","highlight-current-row":"","keep-source":"","show-overflow":"","size":"small"},on:{"current-change":_vm.handleEnumClick}},[_c('vxe-table-column',{attrs:{"type":"seq"}}),_c('vxe-table-column',{attrs:{"edit-render":{name: 'input'},"field":"enum","title":"添加包含的值列表"}})],1),_c('a-button',{on:{"click":_vm.addEnum}},[_vm._v("添加")]),_c('a-button',{on:{"click":_vm.removeEnum}},[_vm._v("删除")]),_c('a-button',{on:{"click":_vm.saveEnum}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){_vm.showAddEnum=false}}},[_vm._v("取消")])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }