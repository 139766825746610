<template>
  <div>
    <p class="no-tip-title">
      <cc-iconfont name="TIP" color="#FFA30E" :size="16"></cc-iconfont>
      暂无数据
    </p>
    <p class="no-data-tip">请联系管理员授权数据源权限。</p>
<!--    <p>请先至系统管理频道完成基础配置!</p>-->
  </div>
</template>
<script>
export default {
  name: 'NoDataTip'
};
</script>
<style lang="less" scoped>
.no-tip-title{
  font-size: 14px;
  color: @white;
  margin-bottom: 4px;
  text-align: center;
}
.no-data-tip{
  color: #999999;
  text-align: center;
}
</style>
