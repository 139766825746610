<template>
  <div class="edit-index">
    <vxe-grid
      :columns="columns"
      :data="editIndexData"
      size="mini"
      :height="viewHeight - 65"
      ref="index_table"
      @cell-click="handleCellClick"
      @edit-closed="handleEditBlur"
      @edit-actived="handleEditActive"
      border
      highlight-current-column
      highlight-current-row
      highlight-hover-column
      highlight-hover-row
      show-overflow
    >
      <template #name_edit="{row, rowIndex}">
        <vxe-input v-model="row.name" @blur="updateRow(row, rowIndex, ['name'])"/>
      </template>
      <template #column_edit="{ row }">
        <vxe-select v-model="row.columnList" multiple transfer>
          <vxe-option v-for="column in columnNameList" :key="column" :value="column" :label="column"/>
        </vxe-select>
      </template>
      <template #type_edit="{ row }">
        <vxe-select v-model="row.type" size="mini" transfer @change="handleChangeIndex">
          <vxe-option v-for="t in typeArr" :key="t" :value="t" :label="t"/>
        </vxe-select>
      </template>
    </vxe-grid>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import Vue from 'vue';
import { Modal } from 'ant-design-vue';

const EMPTY_COLUMN = {
  name: '',
  type: '',
  columns: []
};

export default {
  name: 'EditIndex',
  props: {
    initEditorData: Object,
    history: Array,
    indexData: Array,
    columnNameList: Array,
    viewHeight: Number,
    changeRowPrimaryKey: Function,
    pushHistory: Function,
    schemaEditorApply: Function,
    isMySQL: Boolean,
    deleteHistory: Function
  },
  data() {
    return {
      columns: [
        {
          title: '名称',
          field: 'name',
          editRender: {},
          slots: {
            edit: 'name_edit'
          }
        },
        {
          title: '字段',
          field: 'columnList',
          editRender: {},
          slots: {
            edit: 'column_edit'
          }
        },
        {
          title: '索引类型',
          field: 'type',
          editRender: {},
          slots: {
            edit: 'type_edit'
          }
        }
      ],
      editIndexData: [],
      selectedColumn: {},
      editedColumn: {},
      addColumns: [],
      deleteColumns: []
    };
  },
  created() {
    this.editIndexData = cloneDeep(this.indexData);
    this.typeArr = ['Normal', 'Unique'];
  },
  methods: {
    handleEditActive(event) {
      const { row } = event;
      this.editedColumn = cloneDeep(row);
    },
    handleEditBlur(event) {
      const {
        row,
        column
      } = event;
      switch (column.title) {
        case '名称':
          if (row.name === this.editedColumn.name) {
            return;
          }
          break;
        case '字段':
          if (isEqual(row.columnList, this.editedColumn.columnList)) {
            return;
          }
          break;
        case '索引类型':
          if (row.type === this.editedColumn.type) {
            return;
          }
          break;
        default:
          break;
      }
      let type = '';
      let args = null;

      switch (column.title) {
        case '名称':
          type = 'INDEX_RENAME';
          args = {
            NAME: this.editedColumn.name,
            NEW_NAME: row.name
          };

          if (!this.editedColumn.new) {
            this.schemaEditorApply([{
              type,
              args
            }]);
          }
          return;
        case '字段':
        case '索引类型':
          const dropAction = {
            type: 'INDEX_DROP',
            args: { NAME: this.editedColumn.name }
          };

          const names = row.columnList.map((name) => ({ NAME: name }));

          const createAction = {
            type: 'INDEX_CREATE',
            args: {
              NAME: this.editedColumn.name,
              UNIQUE: row.type === 'unique',
              COLUMNS: names
            }
          };

          if (!this.editedColumn.new) {
            this.schemaEditorApply([dropAction, createAction]);
          }
          break;
        default:
          break;
      }
    },
    updateRow(row, index, type) {
      if (type.length === 1) {
        if (!isEqual(row[type[0]], this.selectedColumn[type[0]])) {
          row.edited = true;
          Vue.set(this.editIndexData, index, row);
        }
      }
    },
    handleCellClick(e) {
      this.selectedColumn = cloneDeep(e.row);
    },
    handleChangeIndex({ value }) {
      const { columns } = this.selectedColumn;
      if (value === 'Primary') {
        this.changeRowPrimaryKey(columns, true);
      } else {
        if (this.selectedColumn.type === 'Primary') {
          this.changeRowPrimaryKey(columns, false);
        }
      }
      Vue.set(this.selectedColumn, 'type', value);
    },
    async addRow() {
      const { row: newRow } = await this.$refs.index_table.insertAt(EMPTY_COLUMN, -1);
      newRow.new = true;
      await this.$refs.index_table.setActiveRow(newRow);
      await this.$refs.index_table.setCurrentRow(newRow);
      await this.$refs.index_table.setActiveCell(newRow, 'name');
      this.selectedColumn = newRow;
      const { _XID } = newRow;
      this.editIndexData.push(this.selectedColumn);
      this.addColumns.push(_XID);
    },
    async removeRow() {
      await this.$refs.index_table.removeCurrentRow();
      const { _XID, name } = this.selectedColumn;
      const index = this.addColumns.indexOf(_XID);
      const historyIndex = this.history.findIndex((h) => h.type === 'INDEX_CREATE' && h.args.NAME === name);
      console.log(index, this.selectedColumn);
      if (index > -1 || historyIndex > -1) {
        if (index > -1) {
          this.addColumns.splice(index, 1);
        }

        if (historyIndex > -1) {
          this.deleteHistory(historyIndex);
        }
      } else {
        // this.deleteColumns.push(_XID);
        // const actions = [];
        const deletes = [];
        this.editIndexData.forEach((column) => {
          if (_XID === column._XID) {
            deletes.push({ type: 'INDEX_DROP', args: { NAME: column.name } });
          }
        });

        if (deletes.length) {
          this.schemaEditorApply(deletes, false, true);
        }
      }
      this.selectedColumn = {};
      const editIndex = this.editIndexData.findIndex((c) => c._XID === _XID);
      this.editIndexData.splice(editIndex, 1);
    },
    async switchTab(history = false) {
      console.log('index history', history);
      const insertRecords = [];
      this.editIndexData.forEach((column) => {
        if (column.new) {
          insertRecords.push(column);
        }
      });
      const adds = [];
      let hasError = false;
      let errorData = {};
      const preIndexNames = this.initEditorData.editorData.indices.map((index) => index.name);
      insertRecords.forEach((record) => {
        let names = [];
        if (record.columnList) {
          names = record.columnList.map((name) => ({ NAME: name }));
        }

        if (!record.name && !hasError) {
          hasError = true;
          errorData = {
            data: record,
            key: 'name',
            msg: '请填写名称'
          };
        }

        if (!names.length && !hasError) {
          hasError = true;
          errorData = {
            data: record,
            key: 'columnList',
            msg: '请填写字段'
          };
        }

        if (record.name && !hasError && preIndexNames.includes(record.name)) {
          hasError = true;
          errorData = {
            data: record,
            key: 'name',
            msg: `${record.name}索引名称重复`
          };
        }

        const columns = {
          type: 'INDEX_CREATE',
          args: {
            NAME: record.name,
            UNIQUE: record.type === 'unique',
            COLUMNS: names
          }
        };

        if (!hasError) {
          adds.push(columns);
        }

        return true;
      });

      if (adds.length && !hasError) {
        if (history) {
          adds.forEach((add) => {
            this.pushHistory(add);
          });
        } else {
          this.schemaEditorApply(adds, false, true);
        }
      }

      if (hasError) {
        Modal.error({
          title: errorData.msg,
          okText: '确定',
          onOk: async () => {
            await this.$refs.index_table.setActiveRow(errorData.data);
            await this.$refs.index_table.setCurrentRow(errorData.data);
            await this.$refs.index_table.setActiveCell(errorData.data, errorData.key);
          }
        });
      }

      return !hasError;
    }
  }
};
</script>

<style scoped lang="less">
.edit-index {
  .op {
    padding: 0 5px;
    width: 100%;
    height: 40px;
    border-top: 1px solid #eee;
    display: flex;
    align-items: center;
  }
}
</style>
