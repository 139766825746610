<template>
  <div class="data-view">
    <vxe-grid
      border
      :columns="columnList"
      :data="dataList"
      :edit-config="editConfig"
      size="mini"
      show-overflow
      resizable
      :toolbar-config="toolbarConfig"
      :export-config="exportConfig"
      :checkbox-config="{}"
      highlight-current-column
      highlight-current-row
      highlight-hover-column
      highlight-hover-row
    >
      <template #toolbar_left>
        <div class="query">
          <a-input v-model="query.order" placeholder="ORDER"/>
          <a-input v-model="query.where" placeholder="WHERE"/>
<!--          <a-input v-model="query.text" placeholder="当前数据查询"/>-->
          <a-button @click="handleQuery" type="primary" ghost>查询</a-button>
        </div>
      </template>
    </vxe-grid>
    <!--    <vxe-toolbar ref="dataTable" custom/>-->
    <!--    <vxe-table :data="dataListFilter" :edit-config="{trigger: 'click', mode: 'cell'}" border size="mini" show-overflow-->
    <!--               resizable-->
    <!--               ref="dataTable" :export-config="exportConfig">-->
    <!--      <vxe-table-column v-for="column in columnList" :key="column.field" :edit-render="column.editRender"-->
    <!--                        :field="column.field" :title="column.title" :min-width="column.width"-->
    <!--                        sortable></vxe-table-column>-->
    <!--    </vxe-table>-->
    <div class="operation">
      <a-select v-model="pageSize" @change="onRun()">
        <a-select-option v-for="page in pageSizeOptions" :value="page" :key="page">{{ page }}</a-select-option>
      </a-select>
      <a-button :disabled="currentPage<=0" ghost type="primary" @click="handlePre">上一页</a-button>
      {{ currentPage + 1 }}
      <a-button :disabled="dataList.length < pageSize" ghost type="primary" @click="handleNext">下一页</a-button>
    </div>
  </div>
</template>

<script>

import { MYSQL_DATA_TYPE } from '@/consts';
// import XEUtils from 'xe-utils';

export default {
  name: 'DataView',
  props: {
    tab: Object,
    createSession: Function
  },
  data() {
    return {
      // 表格属性
      toolbarConfig: {
        slots: {
          buttons: 'toolbar_left'
        },
        refresh: {
          query: this.onRun
        },
        export: true,
        custom: true
      },
      editConfig: {
        trigger: 'click',
        mode: 'cell',
        showStatus: true
      },
      exportConfig: {
        types: ['csv']
      },
      query: {
        text: '',
        where: '',
        order: ''
      },
      sql: '',
      data: [],
      dataList: [],
      dataListFilter: [],
      columnList: [],
      structData: [],
      currentPage: 0,
      pageSize: 10,
      pageSizeOptions: [1, 5, 10, 20, 50, 100, 200, 500]
    };
  },
  methods: {
    handleQuery() {
      // const filterName = XEUtils.toValueString(this.query.text)
      //   .trim()
      //   .toLowerCase();
      // console.log(filterName);
      // if (filterName) {
      //   const filterRE = new RegExp(filterName, 'gi');
      //   const searchProps = this.data.columnList;
      //   this.dataListFilter = this.dataList.map((row) => {
      //     const item = { ...row };
      //     searchProps.forEach((key) => {
      //       item[`render_${key}`] = XEUtils.toValueString(item[key])
      //         .replace(filterRE, (match) => `<span class="keyword-lighten">${match}</span>`);
      //     });
      //     return item;
      //   });
      // } else {
      //   this.dataListFilter = this.dataList;
      // }
    },
    handlePre() {
      this.currentPage--;
      this.onRun();
    },
    handleNext() {
      this.currentPage++;
      this.onRun();
    },
    generateColumnList() {
      const columns = [{
        type: 'checkbox',
        width: 50
      }];
      const {
        columnList,
        resultSet
      } = this.data;

      if (columnList) {
        columnList.forEach((column, index) => {
          let width = 0;
          if (resultSet && resultSet[0]) {
            const value = resultSet[0][index];
            if (value && value.length > column.length) {
              width = value.length * 5 + 80;
            }
          }
          if (width === 0) {
            width = column.length * 5 + 80;
          }
          let editRenderName = 'input';
          if (MYSQL_DATA_TYPE.TIME.includes(this.structData[column].dbType)) {
            editRenderName = 'time';
          }
          columns.push({
            field: column,
            title: column,
            minWidth: width,
            editRender: {
              name: `vxe-${editRenderName}-tpl`
            }
          });
        });
      }

      this.columnList = columns;
    },
    generateDatalist() {
      const list = [];
      const {
        resultSet,
        columnList
      } = this.data;
      if (resultSet) {
        resultSet.forEach((row) => {
          const length = row.length;
          const newRow = {};
          for (let i = 0; i < length; i++) {
            newRow[columnList[i]] = row[i];
            newRow[`render_${columnList[i]}`] = row[i];
          }
          list.push(newRow);
        });
      }

      this.dataList = list;
    },
    async listTabColumns() {
      const data = {
        dataSourceId: this.tab.instanceId,
        parentData: '',
        schemaName: this.tab.database,
        tableName: this.tab.selectedTable,
        useVisibility: true
      };
      const res = await this.$services.listTabColumns({ data });

      const matchReg = (column) => {
        const reg = /(.+?)\((.+?)\)/;
        return reg.exec(column);
      };

      if (res.success) {
        const temp = {};
        res.data.forEach((item) => {
          const result = matchReg(item.columnType);
          if (result) {
            item.columnTypeBase = result[1];
            item.columnTypeNum = result[2];
          }

          temp[item.name] = item;
        });

        this.structData = temp;
      }
    },
    onRun(selectedSql = '') {
      if (!selectedSql) {
        selectedSql = `${this.sql} LIMIT ${this.currentPage * this.pageSize},${this.pageSize};`;
      }
      const data = {
        type: 'WS_EXEC',
        object: {
          sessionId: this.tab.sessionId,
          queryString: selectedSql,
          queryTimeout: 10,
          dataSourceType: this.tab.dataSourceType,
          currentDb: this.tab.database
        }
      };
      this.tab.resultList = [];
      this.createWebSocket();
      if (!this.tab.autoCommit) {
        this.tab.readyToCommit = true;
      }
      this.ws.onclose = () => {
        console.log(`llws连接关闭!${new Date().toLocaleString()}`);
      };
      this.ws.onerror = () => {
        console.log('llws连接错误!');
        this.running = false;
      };
      this.ws.onopen = () => {
        console.log(`llws连接成功!${new Date().toLocaleString()}`);
        this.ws.send(JSON.stringify(data));
      };
      this.ws.onmessage = (event) => { // 如果获取到消息，心跳检测重置
        console.log(event);
        const queryData = JSON.parse(event.data);
        if (queryData.type === 'WS_RESULT') {
          if (queryData.object.success) {
            this.data = queryData.object;
            this.generateColumnList();
            this.generateDatalist();
            // this.handleQuery();
          } else {
            this.errorMessage = `【error】${queryData.object.message}`;
          }
        } else if (queryData.type === 'WS_ERROR') {
          this.errorMessage = `【error】${queryData.object}`;
        } else if (queryData.type === 'WS_RULES') {
          this.errorMessage = `【安全规则】${queryData.object.ruleNames.join('；')}`;
        } else if (queryData.type === 'WS_DONE') {
          this.ws.close();
          this.running = false;
        }
      };
      // this.analysisSplit(selectedSql);
    },
    createWebSocket() {
      try {
        if ('WebSocket' in window) {
          const protocol = document.location.protocol;
          let wsPre = 'ws';
          if (protocol === 'https:') {
            wsPre = 'wss';
          }
          this.ws = new WebSocket(`${wsPre}://${window.location.host}/clouddm/console/api/v1/query_socket`);
        }
      } catch (e) {
        console.log(e);
        this.errorMessage = '创建websocket连接失败，请重试';
      }
    },
    selectAll() {
      this.sql = `SELECT * FROM ${this.tab.selectedTable}`;
      this.onRun();
    }
  },
  async created() {
    // await this.createSession(this.tab);
    await this.listTabColumns();
    this.selectAll();
  },
  watch: {
    async tab() {
      await this.createSession(this.tab);
      await this.listTabColumns();
      this.selectAll();
    }
  }
};
</script>

<style lang="less" scoped>
.data-view {
  .query {
    display: flex;
    width: 500px;
    margin-left: 20px;

    & > input {
      margin-right: 10px;
    }
  }
}
</style>
