<template>
  <div class="edit-basic">
    <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label-position="right">
      <a-form-item label="表名称">
        {{editBasicData.name}}
      </a-form-item>
      <a-form-item label="字符集" v-if="editBasicData.attributeMap && editBasicData.attributeMap.collation">
        {{ editBasicData.attributeMap ? editBasicData.attributeMap.collation : '' }}
      </a-form-item>
      <a-form-item label="创建时间" v-if="editBasicData.attributeMap && editBasicData.attributeMap.create_time">
        {{ editBasicData.attributeMap ? editBasicData.attributeMap.create_time : '' }}
      </a-form-item>
      <a-form-item label="修改时间" v-if="editBasicData.attributeMap && editBasicData.attributeMap.update_time">
        {{ editBasicData.attributeMap ? (editBasicData.attributeMap.update_time || '未修改过') : '未修改过' }}
      </a-form-item>
      <a-form-item label="备注" v-if="editBasicData.comment">
        {{editBasicData.comment}}
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import deepClone from 'lodash.clonedeep';

export default {
  name: 'EditBasic',
  props: {
    basicData: Object,
    pushHistory: Function,
    schemaEditorApply: Function
  },
  data() {
    return {
      editBasicData: {}
    };
  },
  watch: {
    basicData: {
      handler() {
        this.editBasicData = deepClone(this.basicData);
      },
      immediate: true
    }
  },
  methods: {
    switchTab() {
      // return { data: this.editBasicData };
      this.schemaEditorApply([]);
    },
    handleNameBlur() {
      if (this.editBasicData.name !== this.basicData.name) {
        const data = {
          type: 'TABLE_RENAME',
          args: {
            NEW_NAME: this.editBasicData.name
          }
        };

        this.pushHistory(data);
      }
    },
    handleCommentBlur() {
      if (this.editBasicData.comment !== this.basicData.comment) {
        const data = {
          type: 'TABLE_COMMENT',
          args: {
            NEW_COMMENT: this.editBasicData.comment
          }
        };

        this.pushHistory(data);
      }
    }
  }
};
</script>

<style scoped>

</style>
